import React, { useContext, useEffect, useState } from "react";
import IconSelect from "../iconSelect/IconSelect";
import { AppContext } from "../../context/AppContext";
import { Spring, animated } from "react-spring";
import useSound from "use-sound";
import soundFive from "../../assets/sounds/soundFive.wav";
import axios from "../../config/axios-instance";
import qs from "qs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const InputIndustryType = () => {
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [play] = useSound(soundFive);
  const [industryType, setIndustryType] = useState({});
  const [currentHeading, setCurrentHeading] = useState("");
  const [currentKey, setCurrentKey] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  console.log(currentKey);
  const onClick = (IndustryType) => {
    setUserInfo({
      ...userInfo,
      business_type: IndustryType,
      progress: 61,
      currentKey: currentKey,
    });
  };

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/get_by_previous_option",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        q_number: "2",
        previous_op: userInfo.industry,
        industry: userInfo.industry,
      }),
    })
      .then(function (response) {
        let industryTypeOptions = Object.fromEntries(
          Object.entries(response.data).filter(([key]) =>
            key.includes("q_option_")
          )
        );
        setCurrentKey(response.data.current_key);
        setCurrentHeading(response.data.current_q);
        setIndustryType(industryTypeOptions);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [userInfo]);

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center w-full py-9">
        <Spin tip="Loading..." indicator={antIcon} />
      </div>
    );
  } else {
    return (
      <Spring
        from={{ opacity: 0, marginBottom: -500 }}
        to={{ opacity: 1, marginBottom: 0 }}
      >
        {(styles) => (
          <animated.div style={styles}>
            <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
              <div className="py-2 text-lg text-justify break-normal">
                What type of {currentHeading} do you run?
              </div>
              <div className="flex h-48 pt-3 overflow-x-auto">
                {Object.values(industryType).map(function (element, index) {
                  return (
                    <div key={index}>
                      <button
                        onClick={() => {
                          play();
                          onClick(element);
                        }}
                      >
                        <IconSelect name={element} />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </animated.div>
        )}
      </Spring>
    );
  }
};

export default InputIndustryType;
