import * as React from "react";

function SvgBnb({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M32 15.408c-.486.561-.948 1.146-1.467 1.675-.32.327-.86.272-1.227-.038-.127-.108-.244-.226-.366-.338l-.417-.375v8.085c0 .479-.15.707-.462.708-.311 0-.474-.235-.474-.703v-8.667a.598.598 0 00-.196-.49c-3.715-3.45-7.428-6.904-11.141-10.36-.067-.063-.14-.12-.226-.193-.124.111-.238.214-.35.319-3.631 3.376-7.263 6.752-10.897 10.126a.885.885 0 00-.313.722c.009 4.574.01 9.148.006 13.723 0 .815.188 1 1.016 1h21.083c.104 0 .208.004.312 0 .457-.032.703-.278.707-.74.008-.917 0-1.83.004-2.745a2.03 2.03 0 01.011-.31c.041-.275.235-.441.491-.417a.43.43 0 01.427.448c.011.25.006.5.006.75 0 .75.005 1.5 0 2.245-.009 1.018-.654 1.674-1.666 1.709H5.372c-.495 0-.949-.093-1.322-.445-.39-.369-.516-.827-.515-1.352.006-4.314.006-8.628 0-12.943v-.468l-.604.557c-.618.571-1.106.552-1.686-.067-.299-.318-.599-.636-.892-.96-.41-.453-.392-1.015.056-1.44.56-.528 1.124-1.05 1.69-1.57.262-.242.547-.25.738-.032.18.205.147.479-.1.713-.575.54-1.153 1.077-1.75 1.637l1.121 1.205c.378-.35.735-.68 1.091-1.012l12.05-11.22c.61-.566.95-.568 1.553-.006 4.294 3.992 8.586 7.986 12.878 11.98.083.077.166.15.277.25l1.118-1.202c-.275-.257-.531-.5-.788-.739L16.517 1.622c-.403-.376-.575-.375-.975 0l-10.5 9.77c-.182.167-.364.341-.549.509-.277.25-.537.269-.739.056-.203-.212-.174-.48.095-.733.583-.547 1.172-1.09 1.758-1.635C8.718 6.694 11.83 3.797 14.941.9c.39-.365.833-.594 1.353-.448.315.094.605.26.847.482 1.853 1.701 3.69 3.417 5.532 5.133.075.07.153.138.286.258 0-.454.005-.852-.007-1.25 0-.048-.088-.103-.145-.14a.847.847 0 01-.422-.78c.004-.562-.006-1.123.004-1.684.008-.5.347-.844.847-.847a630.1 630.1 0 015.021 0c.5 0 .844.334.86.841.017.583.01 1.167 0 1.746-.006.31-.16.544-.417.727a.45.45 0 00-.167.315c-.01 2.017-.01 4.034 0 6.05.01.139.069.27.167.367.876.833 1.767 1.642 2.64 2.473.243.23.438.51.655.767l.005.5zM23.9 5.064c0 .693-.006 1.356.008 2.018a.461.461 0 00.16.277 648.632 648.632 0 003.328 3.1c.055.043.113.082.174.118V5.064H23.9zm4.26-.958V2.573h-4.815v1.533h4.816z"
        fill="currentColor"
      />
      <path
        d="M22.967 28.18h-9.181c-.75 0-1.025-.198-1.269-.918H9.1c0 .372-.01.733 0 1.092.045 1.292-.118 1.535-1.485 1.47h-.093c-.643-.004-.917-.27-.917-.904V17.006c0-.8.234-1.03 1.045-1.03h.593c.544.009.845.305.852.855.007.57 0 1.141 0 1.75h3.064c.833 0 1.223.34 1.333 1.193H24.27c.808 0 1.19.376 1.19 1.174v7.953c0 .66-.265.92-.929.923-.26 0-.52.006-.779 0-.459-.014-.756-.3-.779-.763-.017-.28-.006-.561-.006-.882zm1.534-7.45H13.485v6.49H24.5v-6.49zM9.121 22.093v2.644h3.395v-2.644H9.122zm-.979 6.78V16.937H7.56v11.935h.583zm4.393-7.75c0-.47.013-.906-.01-1.34-.005-.094-.139-.255-.216-.256-1-.018-1.992-.016-2.987 0a.294.294 0 00-.213.191c-.02.464-.01.928-.01 1.409l3.436-.004zM9.11 26.281h3.402v-.562H9.11v.562zm14.807 1.909v.673h.583v-.673h-.583z"
        fill="currentColor"
      />
      <path
        d="M13.15 16.078c-.083-.099-.122-.154-.166-.202a3.775 3.775 0 01-1.1-2.604c-.01-.363-.01-.727 0-1.09.01-.388.172-.56.554-.561 2.17-.005 4.342-.005 6.513 0 .368 0 .524.17.54.537.005.102 0 .204 0 .313l.303.06c.66.122 1.145.737 1.092 1.387-.056.697-.595 1.255-1.281 1.238-.454-.012-.798.072-1.02.5-.072.14-.212.246-.37.422h1.196c.124-.004.249 0 .373.01a.456.456 0 01.083.886c-.12.03-.244.043-.368.039h-7.604a2.086 2.086 0 01-.25-.006c-.271-.034-.454-.227-.454-.47 0-.255.197-.444.49-.456.238-.01.477 0 .716 0l.754-.003zm5.38-3.5h-5.694c-.11 1.277.22 2.348 1.333 3.04 1.03.643 2.1.629 3.112-.041 1.059-.703 1.375-1.757 1.249-2.994v-.005zm.842 1.615c.34.057.535-.053.569-.311.033-.259-.138-.41-.448-.413l-.12.726v-.002zM17.832 8.941c-.088.25-.146.517-.274.745-.166.295-.398.54-.44.908-.025.22-.245.338-.477.307-.258-.035-.447-.214-.405-.465.043-.324.15-.635.313-.917.39-.606.394-.627-.004-1.238a2.377 2.377 0 01-.31-.857.434.434 0 01.416-.516c.27-.022.433.142.512.433.057.206.15.4.273.575.225.298.363.653.396 1.025zM15.939 8.898l-.427.954c-.1.22-.217.436-.296.666-.084.246-.231.394-.495.389-.25-.006-.425-.209-.435-.48-.013-.359.121-.666.334-.948.39-.52.39-.63 0-1.147-.213-.282-.349-.587-.334-.946.01-.28.177-.462.431-.479.254-.016.426.146.504.411.076.21.167.413.272.61l.446.97z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBnb;
