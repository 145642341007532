import React from 'react'


const PlotCard = ({ name }) => {
    return (
        <div >
            <div className="ml-2 w-20 h-16 border-r-2 border-blue-600"></div>
            <div className="pl-20 flex">
                <span class="self-center flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-5 w-5 rounded-full bg-blue-400 opacity-75"></span>
                    <div className="">
                        <span class="relative inline-flex rounded-full h-3 w-3 ml-1 bg-blue-600"></span>
                    </div>
                </span>
                {/* <FaArrowRight className="self-center text-blue-800" size="24" /> */}
                <div className=" cursor-pointer pl-4 self-center text-gray-700 text-lg transform hover:scale-110 hover:text-black " >{name["solution"]}</div>
            </div>
        </div>
    )
}

export default PlotCard
