import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/navbar/Navbar";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Solution from "../components/solutions/solution";
import _ from "lodash";

const CustomerRelationshipStation = () => {
  const navigate = useNavigate();
  const [userInfo] = useContext(AppContext);
  const cardData = userInfo.cardData;
  const location = useLocation();
  const solutionOptions = location.state.solutionOptions;

  const stationOptions = solutionOptions.filter(
    (options) => options["Station"] === "Customer Relationship Management"
  );

  const GroupedBysolution = _.mapValues(
    _.groupBy(stationOptions, "Solution "),
    (solutionlist) => solutionlist
  );

  const handleNext = () => {
    navigate("/loan-insurance-station", {
      state: { solutionOptions: solutionOptions },
    });
  };

  if (!localStorage.getItem("authorization_token")) {
    return <Navigate to="/" />;
  }
  if (cardData.length === 0) {
    return <Navigate to="/onboarding" />;
  }
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-200">
      <Navbar />
      <div className=" min-h-screen flex justify-center mt-16 mb-16 md:w-full ">
        <div className=" p-6 md:p-10  rounded-lg  w-full md:w-9/12 lg:w-1/2">
          <div className="">
            <div className="flex justify-between  shadow-2xl  rounded-md font-semibold text-white text-xl bg-yellow-500 align-middle mb-4">
              <div
                className="bg-gray-700 py-4 px-4 rounded-md cursor-pointer"
                onClick={() => {
                  navigate("/growth-station", {
                    state: { solutionOptions: solutionOptions },
                  });
                }}
              >
                <FaArrowLeft size="24" color="white" />
              </div>
              <div className="py-4">Customer Relationship Station</div>
              <div
                className="bg-gray-700 py-4 px-4 rounded-md cursor-pointer"
                onClick={handleNext}
              >
                <FaArrowRight size="24" color="white" />
              </div>
            </div>
            <div className=" shadow-2xl py-4 rounded-md text-center font-semibold  text-lg bg-white align-middle mb-4">
              Customer Relationship Station pe aapka swagat hai!
            </div>
            {stationOptions.length !== 0 ? (
              <div className="text-lg text-justify mb-4">
                You are here because Increasing Market reach is your priority.
                here are some relevant solutions to achieve increased market
                reach.
              </div>
            ) : (
              <div className="texttext-lg text-justify mb-4 flex justify-center   items-center w-full uppercase tracking-widest pt-4 ">
                No solution at this station.
              </div>
            )}
            <div className="w-full flex justify-center items-center">
              <div className=" w-96 lg:w-full">
                {Object.values(GroupedBysolution).map((products) => (
                  <Solution
                    solutionOptions={products}
                    heading={products[0]["Solution "]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full bottom-0 bg-white h-16 border-b-2 fixed z-30">
        <div className="w-96 md:w-1/2 h-full flex justify-around">
          <button
            onClick={() => {
              navigate("/journey");
            }}
            className=" h-3/4 w-32 border-2 border-blue-800 rounded-md self-center transform hover:scale-110 transition duration-200 ease-in-out hover:bg-blue-700 hover:text-white font-semibold text-blue-600"
          >
            View Journey
          </button>
          <button
            onClick={handleNext}
            className="h-3/4 w-32 border-2 border-blue-800 rounded-md self-center transform hover:scale-110 transition duration-200 ease-in-out hover:bg-blue-700 hover:text-white font-semibold text-blue-600"
          >
            Continue Journey
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerRelationshipStation;
