import React from "react";
import { FaStar } from "react-icons/fa";

function Solution(props) {
  return (
    <div className="flex overflow-x-scroll mt-10">
      <div className="text-lg mr-2">{props.heading}</div>
      {props.solutionOptions.map((item, key) => {
        return (
          <div key={key}>
            <div className="w-56 bg-white px-2 m-2 rounded">
              <p className="bg-blue-400 text-white text-center h-11 ">
                {item["Solution Sub-Category"]}
              </p>
              <div className="bg-white rounded-sm">
                <div className="flex items-center justify-center">
                  <img
                    className="h-24 text-center"
                    src={item.image_url}
                    alt="img"
                  />
                </div>
                <div className="mt-2 text-center font-semibold h-28">
                  {item["SKU Name"]}
                </div>
                <div>{item["Solution Type"]}</div>
                <div className="flex">
                  <FaStar className="mt-1" color="orange" />
                  <div className="ml-2">{item["Rating "]}</div>
                </div>
                <div>Starting from</div>
                <div className="font-semibold mb-3">
                  {item["Price Starting from"]}
                </div>
                <div className="bg-green-400 text-white text-sm mb-3">
                  Earn upto 50%
                </div>
                <div className="py-3 bg-blue-800 text-center text-white rounded-sm font-semibold">
                  Buy Now
                </div>
                <div className="py-4 text-center text-blue-600 font-semibold">
                  Add to Package
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Solution;
