import * as React from "react";

function SvgHostels({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M29.718 13.907H2.29v4.479c0 .767-.469 1.293-1.145 1.293C.468 19.68 0 19.153 0 18.386V1.24C0 .564.415.073 1.016.006c.275-.029.55.044.777.205.226.161.387.4.453.672.032.165.044.333.037.501V11.558h9.143V4.815c0-.893.438-1.342 1.308-1.342h13.462c3.276 0 5.794 2.548 5.8 5.862.005 3.038.005 6.077 0 9.115 0 .58-.293 1-.785 1.167-.468.16-.995.015-1.262-.415-.14-.233-.219-.5-.228-.773-.022-1.362-.01-2.725-.01-4.087l.007-.435zM13.735 11.55h15.983V9.468c0-2.201-1.456-3.679-3.626-3.679h-12.36l.003 5.762z"
        fill="currentColor"
      />
      <path
        d="M6.865 10.42c-.678 0-1.34-.202-1.904-.583a3.462 3.462 0 01-1.265-1.556A3.513 3.513 0 013.5 6.276a3.486 3.486 0 01.935-1.779 3.415 3.415 0 011.753-.953 3.388 3.388 0 011.98.195 3.437 3.437 0 011.541 1.275c.378.57.58 1.241.581 1.928a3.506 3.506 0 01-1.003 2.454 3.418 3.418 0 01-2.42 1.024zm1.138-3.474c0-.23-.067-.454-.194-.645a1.131 1.131 0 00-1.765-.169A1.165 1.165 0 005.805 7.4c.089.212.238.392.428.518.19.125.413.19.64.188.3-.006.587-.13.798-.347.212-.216.33-.508.332-.813z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHostels;
