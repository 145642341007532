import React, { useContext, useEffect, useState } from "react";
import IconSelect from "../iconSelect/IconSelect";
import { AppContext } from "../../context/AppContext";
import { Spring, animated } from "react-spring";
import useSound from "use-sound";
import soundFour from "../../assets/sounds/soundFour.wav";
import axios from "../../config/axios-instance";
import qs from "qs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const InputIndustry = () => {
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [play] = useSound(soundFour);
  const [industry, setIndustry] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const onClick = (Industry) => {
    setUserInfo({
      ...userInfo,
      industry: Industry,
      progress: 50,
      currentKey: "industry_name",
    });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/get_by_previous_option",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        q_number: "1",
        previous_op: "Restaurants",
        industry: "Restaurants",
      }),
    })
      .then(function (response) {
        setIndustry(response.data);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center w-full py-9">
        <Spin tip="Loading..." indicator={antIcon} />
      </div>
    );
  } else {
    return (
      <Spring
        from={{ opacity: 0, marginBottom: -500 }}
        to={{ opacity: 1, marginBottom: 0 }}
      >
        {(styles) => (
          <animated.div style={styles}>
            <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
              <div className="text-xl font-semibold">Awesome! </div>
              <div className="py-2 text-lg text-justify break-normal">
                {userInfo.name}, let’s know {userInfo.buisnessName} business
                better.
              </div>
              <div className="text-xl ">Choose your Industry</div>
              <div className="flex pt-3 overflow-x-auto ">
                {industry.map(function (element, index) {
                  return (
                    <div key={index}>
                      <button
                        onClick={() => {
                          play();
                          onClick(element);
                        }}
                      >
                        <IconSelect name={element} />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </animated.div>
        )}
      </Spring>
    );
  }
};

export default InputIndustry;
