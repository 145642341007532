import React from 'react'
import {
    BuisnessMgmt,
    Communication,
    CustomerRelationship,
    Distribution,
    Growth,
    IndependentIdentity,
    LoanInsurance,
    OnlinePresence,
    Payment,
    Smart
} from '../../assets/journeyComponents/index'


const JourneySelect = ({ name }) => {


    const stationImage = () => {
        switch (name) {
            case 'Business Mgmt Station':
                return <BuisnessMgmt />
            case 'Communications Station':
                return <Communication />
            case 'Customer Relationship Station':
                return <CustomerRelationship />
            case 'Growth Station':
                return <Growth />
            case 'Independent Identity Station':
                return <IndependentIdentity />
            case 'Distribution Station':
                return <Distribution />
            case 'Loan & Insurance Station':
                return <LoanInsurance />
            case 'Online Presence Station':
                return <OnlinePresence />
            case 'Payment & Banking Station':
                return <Payment />
            case 'Smart Station':
                return <Smart />
            default:
                return <Smart />
        }
    };

    return (
        <div className="w-28" >
            <div className='flex flex-col'>
                <div className='flex items-center justify-center'>
                    <div className='text-5xl p-3 text-blue-900 rounded-full shadow-2xl transform hover:scale-105 transition duration-200 ease-in-out hover:text-white w-24 h-24 cursor-pointer flex items-center justify-center'>
                        {stationImage()}
                    </div>
                </div>
                <div className="text-center  h-11">{name}</div>
            </div>

        </div>
    )
}

export default JourneySelect
