import * as React from "react";

function SvgFineDining({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M7.577 18.393c-.816 0-1.615-.004-2.414.01-.06 0-.156.12-.17.195-.237 1.382-.464 2.766-.695 4.149-.16.954-.315 1.908-.483 2.857-.072.415-.335.608-.688.536-.323-.066-.477-.352-.41-.753l1.133-6.793c.008-.05.007-.103.014-.2H3.03c-.495 0-.672-.166-.732-.651-.406-3.248-.814-6.496-1.223-9.744-.05-.409.122-.699.464-.756.332-.056.597.175.65.59.31 2.463.62 4.928.927 7.392.084.669.17 1.337.258 2.022H7.79c.1-.564.21-1.141.313-1.719.088-.49.17-.982.26-1.473.07-.378.252-.541.638-.55.478-.009.955-.003 1.435-.003H22.85c.698 0 .804.095.919.773.158.921.321 1.842.497 2.76.017.087.135.22.208.22 1.392.014 2.784.01 4.213.01l.256-2.009.938-7.484c.043-.343.309-.564.613-.532.335.036.546.314.503.699-.12 1.021-.252 2.041-.38 3.064l-.853 6.773c-.057.45-.243.615-.696.619h-.876c.095.586.183 1.145.277 1.703.297 1.782.596 3.565.896 5.347.061.364-.12.649-.442.706-.346.061-.602-.133-.669-.525-.375-2.222-.747-4.445-1.116-6.669l-.093-.54H24.5c.137.788.269 1.564.406 2.34.423 2.392.847 4.785 1.272 7.177.06.333-.068.56-.363.715-2.193 1.155-4.423 1.241-6.684.223a.596.596 0 00-.548-.015c-2.208.929-4.418.929-6.63 0a.505.505 0 00-.487.024c-1.797.931-3.552.838-5.258-.248-.277-.176-.377-.402-.316-.74.552-3.044 1.098-6.091 1.637-9.14.02-.107.029-.22.047-.36zm15.112-3.74c-.107-.007-.18-.014-.251-.014-4.253 0-8.504-.003-12.755-.007-.231 0-.296.084-.333.292-.275 1.586-.562 3.17-.844 4.754-.47 2.638-.936 5.274-1.414 7.912-.038.208.02.294.196.379 1.05.502 2.116.555 3.201.137.61-.236.61-.242.61-.884v-.468-3.961c0-.402.26-.668.6-.641.322.025.523.27.523.655 0 1.55.005 3.099-.003 4.648 0 .214.05.33.263.415 1.843.732 3.682.736 5.529.014.235-.092.296-.22.295-.462-.01-2.215-.005-4.43-.005-6.644 0-.544.18-.808.552-.819.384-.009.58.267.58.822 0 2.214.004 4.429-.005 6.643 0 .251.06.39.301.494.945.402 1.925.59 2.949.517a5.803 5.803 0 002.325-.649L22.69 14.652z"
        fill="currentColor"
      />
      <path
        d="M16.294 7.928c.742.118 1.406.37 1.988.813 1.105.846 1.7 1.965 1.75 3.361.015.46-.195.678-.654.679-2.442.003-4.884.003-7.326 0-.452 0-.653-.222-.65-.684.016-1.952 1.525-3.752 3.449-4.114l.29-.058c.05-.452-.127-1.088.61-1.06.668.024.495.614.543 1.063zm2.525 3.717c-.007-1.35-1.6-2.652-3.184-2.616-1.515.036-3.064 1.376-3.01 2.616h6.194zM15.591 3.926c0 .351.005.706 0 1.058-.006.371-.231.617-.556.62-.324.003-.562-.242-.566-.61-.006-.716-.006-1.432 0-2.148.003-.375.225-.613.556-.616.33-.002.56.235.566.606.005.363 0 .729 0 1.09zM18.155 5.406c0 .238.009.477 0 .714-.015.313-.235.533-.527.546-.307.013-.573-.201-.585-.521a18.546 18.546 0 010-1.523.543.543 0 01.587-.515c.291.017.502.239.523.553v.746h.002zM13.213 5.973c0 .186.008.373 0 .559-.02.347-.251.577-.568.574-.317-.002-.545-.23-.553-.582a26.386 26.386 0 010-1.15c.008-.332.227-.562.53-.576.312-.015.566.215.588.552.014.206.003.414.003.621v.002z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFineDining;
