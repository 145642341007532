import React, { useState, createContext } from "react";

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [userInfo, setUserinfo] = useState({
    currentKey: null,
    progress: "0",
    name: null,
    buisnessName: null,
    industry: null,
    business_type: null,
    rating: null,
    inventry: null,
    age: null,
    stationHeading: null,
    impact: [],
    cardData: []
  });
  console.log("global", userInfo);

  return (
    <AppContext.Provider value={[userInfo, setUserinfo]}>
      {props.children}
    </AppContext.Provider>
  );
};
