import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/navbar/Navbar";
import { Navigate, useNavigate } from "react-router-dom";
import JourneySelect from "../components/journeySelect/JourneySelect";

const Journey = () => {
  const navigate = useNavigate();
  const [userInfo] = useContext(AppContext);
  const cardData = userInfo.cardData;
  const [solutionOptions, setSolutionOptions] = useState([]);

  console.log(solutionOptions);

  useEffect(() => {
    if (cardData.length === 0) {
      navigate("/loading");
    } else {
      setSolutionOptions(cardData[Object.keys(cardData)[0]].product_map);
    }
  }, [cardData, setSolutionOptions, navigate]);

  if (!localStorage.getItem("authorization_token")) {
    return <Navigate to="/" />;
  }
  return (
    <div className=" min-h-screen bg-gray-200">
      <Navbar />
      <div className=" min-h-screen flex justify-center mb-16 mt-16 ">
        <div className="shadow-2xl md:px-10 rounded-lg bg-white mt-2">
          <div className="border-2 border-blue-400 p-2 bg-blue-100">
            <div className="font-semibold text-lg">
              Congratulations {userInfo.name}
            </div>
            <div className="tracking-wider">
              You have successfully built {userInfo.buisnessName} Digital
              Journey
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="">
              <div className="flex">
                <button
                  onClick={() => {
                    navigate("/customer-relationship-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Customer Relationship Station" />
                </button>
                <div className=" w-6 h-24 mt-12 border-t-2 border-gray-500 border-dashed"></div>
                <button
                  onClick={() => {
                    navigate("/loan-insurance-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Loan & Insurance Station" />
                </button>
                <div className=" w-6 h-24 mt-12 border-t-2 border-gray-500 border-dashed"></div>
                <button
                  onClick={() => {
                    navigate("/smart-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Smart Station" />
                </button>
              </div>
              <div>
                <div className="w-20">
                  <div className=" mr-7 h-8 border-r-2 border-gray-500 border-dashed"></div>
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={() => {
                    navigate("/growth-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Growth Station" />
                </button>
                <div className=" w-6 h-24 mt-12 border-t-2 border-gray-500 border-dashed"></div>
                <button
                  onClick={() => {
                    navigate("/distribution-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Distribution Station" />
                </button>
                <div className=" w-6 h-24 mt-12 border-t-2 border-gray-500 border-dashed"></div>
                <button
                  onClick={() => {
                    navigate("/business-mgmt-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Business Mgmt Station" />
                </button>
              </div>
              <div className=" flex flex-row-reverse">
                <div className="w-20">
                  <div className="ml-6 h-8 border-l-2 border-gray-500 border-dashed"></div>
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={() => {
                    navigate("/independent-identity-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Independent Identity Station" />
                </button>
                <div className=" w-6 h-24 mt-12 border-t-2 border-gray-500 border-dashed"></div>
                <button
                  onClick={() => {
                    navigate("/payment-banking-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Payment & Banking Station" />
                </button>
                <div className=" w-6 h-24 mt-12 border-t-2 border-gray-500 border-dashed"></div>
                <button
                  onClick={() => {
                    navigate("/online-presence-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Online Presence Station" />
                </button>
              </div>
              <div className="w-20">
                <div className=" mr-7 h-8 border-r-2 border-gray-500 border-dashed"></div>
              </div>
              <div className="flex">
                <button
                  onClick={() => {
                    navigate("/communication-station", {
                      state: { solutionOptions: solutionOptions },
                    });
                  }}
                >
                  <JourneySelect name="Communications Station" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full bottom-0 bg-white h-16 border-b-2 fixed z-30">
        <div className="w-96 md:w-1/2 h-full flex justify-around">
          <button className=" h-3/4 w-32 border-2 border-blue-800 rounded-md self-center transform hover:scale-110 transition duration-200 ease-in-out hover:bg-blue-700 hover:text-white font-semibold text-blue-600">
            Edit Profile
          </button>
          <button
            onClick={() => {
              navigate("/communication-station", {
                state: { solutionOptions: solutionOptions },
              });
            }}
            className="h-3/4 self-center w-32 border-2 border-blue-800 rounded-md  transform hover:scale-110 transition duration-200 ease-in-out hover:bg-blue-700 hover:text-white font-semibold text-blue-600"
          >
            Start Journey
          </button>
        </div>
      </div>
    </div>
  );
};

export default Journey;
