import React from 'react'
import SvgDigitalLogo from '../../assets/logoComponent/DigitalLogo'

const Navbar = () => {
    return (
        <div className="flex w-full top-0 bg-white h-16 border-b-2 fixed z-30">
            <div className=" ml-6 self-center text-6xl">
                <SvgDigitalLogo />
            </div>
        </div >
    )
}

export default Navbar
