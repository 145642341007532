import * as React from "react";

function SvgCommunication({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M22.752 32.004c-.118-.195-.28-.377-.339-.588-.059-.212-.013-.453-.013-.736-.241.095-.446.178-.651.255-.349.13-.638.029-.761-.261-.123-.29.01-.57.351-.724.234-.114.475-.212.721-.294.277-.084.358-.26.372-.528.043-.81.425-1.446 1.063-1.927.213-.16.461-.275.712-.42-.49-1.534-.135-2.866 1.204-3.805 1.22-.855 2.505-.749 3.747.09.122-.244.231-.477.354-.705.18-.331.47-.442.75-.297.279.146.358.442.196.783-.142.3-.308.59-.45.89a.413.413 0 00-.037.285c.52 1.378.524 1.408.224 2.789.676.267 1.217.704 1.53 1.376.12.26.185.547.275.82v2.997h-9.248zm.773-1.09h7.405c0-.574.025-1.125-.008-1.674-.04-.674-.41-1.139-1.031-1.4-.182-.078-.337-.097-.497.084-.15.159-.324.294-.514.401-1.27.775-2.747.633-3.848-.383-.174-.162-.32-.184-.52-.101-.504.209-.907.559-.969 1.095-.075.652-.018 1.32-.018 1.979zm3.69-3.2a2.118 2.118 0 10-.062-4.236 2.118 2.118 0 00.063 4.236z"
        fill="url(#communication_svg__paint0_linear_3_2307)"
      />
      <path
        d="M32 9.19c-.131.33-.391.429-.724.418-.185-.006-.372 0-.6 0 .1.257.192.473.269.696.114.326 0 .607-.29.722-.271.109-.548-.01-.688-.313a9.142 9.142 0 01-.334-.806c-.079-.23-.213-.305-.462-.304-1.978.012-3.956.007-5.933.007-.698 0-.84-.144-.84-.846V7.172c-.006-1.313.572-2.246 1.8-2.76-.065-.313-.123-.626-.2-.933a.35.35 0 00-.157-.173c-.284-.172-.577-.33-.86-.502-.325-.195-.424-.484-.273-.76.151-.275.455-.339.783-.171.111.056.218.12.328.18.109.06.199.104.326.169.383-1 1.05-1.703 2.053-2.05a3 3 0 012.192.073c1.43.598 2.445 2.197 1.814 4.089.374.282.758.527 1.088.831.42.387.576.924.704 1.466L32 9.19zm-1.07-.67c0-.58.026-1.123-.006-1.661-.04-.687-.405-1.161-1.045-1.423-.194-.078-.332-.057-.5.096-1.234 1.138-2.928 1.181-4.225.125-.41-.333-.576-.367-.983-.016-.255.22-.54.527-.596.834-.12.664-.1 1.353-.14 2.045h7.496zm-1.606-5.322a2.127 2.127 0 10-4.255.025c.01 1.132.984 2.09 2.128 2.092a2.14 2.14 0 002.127-2.114v-.003z"
        fill="url(#communication_svg__paint1_linear_3_2307)"
      />
      <path
        d="M.381 32.004c-.296-.168-.391-.423-.38-.756.018-.615-.007-1.23.008-1.844.03-1.197.583-2.055 1.689-2.54a.316.316 0 00.057-.031c-.046-.512-.149-.998-.114-1.473.03-.413.199-.82.345-1.216.062-.17.092-.295-.005-.46a9.715 9.715 0 01-.445-.861c-.156-.334-.06-.634.228-.769.274-.13.548-.02.723.298.125.227.235.463.356.705 1.39-.892 2.765-.944 4.039.145 1.113.952 1.366 2.185.917 3.555.303.2.626.37.9.602.52.44.834 1.017.866 1.703.014.34.144.531.464.63.228.074.45.163.665.268.32.15.444.43.325.712-.118.28-.399.39-.73.27-.108-.037-.212-.085-.318-.127-.107-.043-.21-.079-.34-.127-.083.464.137 1.001-.376 1.314l-8.874.002zm8.14-1.073c0-.61.028-1.192-.008-1.77-.04-.626-.408-1.046-.973-1.303-.22-.1-.378-.086-.574.094-1.216 1.12-2.928 1.155-4.2.108-.402-.331-.577-.363-.983-.025-.245.204-.49.479-.593.772-.242.687-.078 1.41-.115 2.124H8.52zm-3.725-3.217a2.118 2.118 0 10.011-4.237 2.118 2.118 0 00-.011 4.237z"
        fill="url(#communication_svg__paint2_linear_3_2307)"
      />
      <path
        d="M32 16.753l-.187 1.632c-.005.04-.006.083-.011.124-.059.447-.272.668-.612.633-.34-.035-.503-.296-.445-.751.157-1.24.242-2.478.133-3.727-.035-.399.173-.645.493-.66.32-.016.536.222.568.607.012.152.04.303.06.454v1.688z"
        fill="url(#communication_svg__paint3_linear_3_2307)"
      />
      <path
        d="M15.003 32.004c-.286-.039-.572-.079-.861-.115-.357-.05-.548-.26-.522-.575.024-.303.27-.519.614-.48 1.168.13 2.347.13 3.516 0 .343-.038.595.172.626.47.031.296-.168.53-.517.581-.285.041-.572.079-.861.115l-1.995.004z"
        fill="url(#communication_svg__paint4_linear_3_2307)"
      />
      <path
        d="M12.81 19.122c-.21.11-.38.156-.488.262-.891.876-1.771 1.762-2.654 2.646-.067.066-.123.165-.203.19-.196.063-.412.153-.601.12-.235-.04-.322-.275-.322-.513V19.2c-.205 0-.378.006-.548 0-.589-.022-1.04-.444-1.043-1.027-.01-2.156-.01-4.311 0-6.467 0-.57.443-.989 1.018-1.018.218-.011.437-.007.656-.007h8.74c.923 0 1.307.386 1.31 1.312 0 .593 0 1.187.004 1.78a.67.67 0 00.027.108h4.596c.82 0 1.23.403 1.233 1.225.005 2 .005 4 0 5.998 0 .912-.378 1.292-1.285 1.302-.091 0-.183.01-.297.015-.006.115-.016.215-.016.313 0 .704-.005 1.416 0 2.125 0 .282-.014.558-.313.689-.322.142-.548-.04-.765-.258-.884-.899-1.78-1.785-2.663-2.68a.66.66 0 00-.52-.214c-1.53.009-3.06.006-4.591.004-.886 0-1.273-.391-1.273-1.274-.004-.633-.003-1.266-.003-2.003zm10.631 2.213v-6.38h-9.548v6.375h.348c1.573 0 3.144.007 4.716 0a1.034 1.034 0 01.801.334c.615.636 1.248 1.253 1.878 1.879.062.06.132.115.243.211v-1.735c0-.488.19-.676.68-.685.283-.006.572-.001.882-.001v.002zm-13.833-.781c.139-.128.223-.2.3-.277.62-.619 1.24-1.234 1.853-1.86a.846.846 0 01.695-.287c.101.007.204 0 .344 0v-.361-2.625c0-.893.381-1.275 1.266-1.278h3.186c.11 0 .219-.01.333-.015v-2.086h-9.56v6.369h.39c1.178 0 1.195.016 1.195 1.18l-.002 1.24z"
        fill="url(#communication_svg__paint5_linear_3_2307)"
      />
      <path
        d="M7.848 4.428c.835.337 1.44.939 1.665 1.872.06.274.092.553.094.833.014.552.004 1.105.004 1.656 0 .663-.156.817-.806.817-1.968 0-3.936.008-5.904-.007-.296 0-.46.079-.552.361-.088.256-.19.506-.307.75-.142.305-.418.424-.688.313-.288-.117-.404-.397-.289-.724.078-.214.166-.423.272-.693-.246 0-.457.005-.669 0-.47-.015-.655-.208-.658-.678 0-.646-.014-1.292 0-1.937.03-1.174.574-2.015 1.644-2.512l.164-.079C1.142 2.374 2.256.77 3.69.204c1.265-.5 3.28-.166 4.193 2.006.21-.114.415-.235.626-.34.33-.17.636-.105.788.164.152.268.055.563-.264.762a.493.493 0 01-.082.045c-.712.268-1.158.735-1.103 1.587zM8.51 8.52c0-.644.053-1.261-.014-1.866-.07-.644-.5-1.06-1.12-1.246a.401.401 0 00-.322.088c-1.409 1.231-3.081 1.233-4.478.008a.46.46 0 00-.379-.078c-.62.196-1.022.634-1.088 1.275-.06.594-.013 1.2-.013 1.818l7.414.001zM2.683 3.17a2.142 2.142 0 002.063 2.148c1.178.025 2.181-.931 2.191-2.091A2.153 2.153 0 004.834 1.07C3.681 1.043 2.704 2 2.683 3.17z"
        fill="url(#communication_svg__paint6_linear_3_2307)"
      />
      <path
        d="M1.134 14.75c-.132 1.23-.027 2.44.128 3.65a.98.98 0 01-.008.37.47.47 0 01-.482.368c-.273.004-.47-.136-.518-.403a7.591 7.591 0 01-.166-1.203 74.69 74.69 0 01-.01-2.99.522.522 0 01.562-.54c.305.018.492.245.494.603v.144z"
        fill="url(#communication_svg__paint7_linear_3_2307)"
      />
      <path
        d="M11.62 1.697a.528.528 0 01-.462-.82.513.513 0 01.318-.214c.547-.15 1.106-.284 1.665-.4.396-.078.802-.122 1.203-.176.376-.05.656.136.699.455.04.3-.175.53-.54.6-.907.175-1.813.356-2.719.534-.061.013-.124.017-.164.021z"
        fill="url(#communication_svg__paint8_linear_3_2307)"
      />
      <path
        d="M20.46 1.735c-.343-.091-.6-.176-.865-.226-.694-.131-1.39-.247-2.085-.368-.373-.064-.574-.288-.536-.599.039-.31.319-.518.695-.448.919.167 1.835.347 2.748.539.151.032.346.124.407.246a.699.699 0 01.03.544c-.067.147-.279.226-.394.312z"
        fill="url(#communication_svg__paint9_linear_3_2307)"
      />
      <path
        d="M17.09 16.539c.488 0 .977-.006 1.465 0 .408.006.635.202.636.527.002.325-.227.533-.63.537-.998.007-1.995.007-2.993 0-.396 0-.633-.227-.626-.548.006-.322.235-.51.618-.517.511-.006 1.02.001 1.53.001z"
        fill="url(#communication_svg__paint10_linear_3_2307)"
      />
      <path
        d="M20.268 18.675c.499 0 .997-.005 1.497 0 .404.004.626.203.626.534 0 .313-.225.524-.608.527-1.008.009-2.016.009-3.024 0-.375 0-.612-.22-.618-.526-.006-.318.235-.53.633-.535.496-.005.995 0 1.494 0z"
        fill="url(#communication_svg__paint11_linear_3_2307)"
      />
      <path
        d="M15.995 19.756a5.967 5.967 0 01-.647-.054c-.272-.051-.402-.248-.402-.518a.488.488 0 01.47-.494c.394-.02.79-.02 1.183 0a.487.487 0 01.462.5c0 .27-.137.465-.41.513-.217.03-.436.047-.656.053z"
        fill="url(#communication_svg__paint12_linear_3_2307)"
      />
      <path
        d="M21.356 16.53c.197.009.396.005.591.03.275.037.421.212.44.485.017.272-.144.506-.421.525-.424.031-.849.031-1.273 0-.277-.02-.402-.241-.414-.504-.013-.262.184-.461.454-.508a.623.623 0 01.093-.014h.53v-.015z"
        fill="url(#communication_svg__paint13_linear_3_2307)"
      />
      <defs>
        <linearGradient
          id="communication_svg__paint0_linear_3_2307"
          x1={18.87}
          y1={27.056}
          x2={33.935}
          y2={27.056}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint1_linear_3_2307"
          x1={20.598}
          y1={5.592}
          x2={33.68}
          y2={5.592}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint2_linear_3_2307"
          x1={-2.074}
          y1={27.056}
          x2={13}
          y2={27.056}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint3_linear_3_2307"
          x1={30.497}
          y1={16.602}
          x2={32.221}
          y2={16.602}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint4_linear_3_2307"
          x1={12.725}
          y1={31.423}
          x2={19.212}
          y2={31.423}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint5_linear_3_2307"
          x1={3.645}
          y1={18.218}
          x2={27.618}
          y2={18.218}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint6_linear_3_2307"
          x1={-1.798}
          y1={5.592}
          x2={11.296}
          y2={5.592}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint7_linear_3_2307"
          x1={-0.159}
          y1={16.597}
          x2={1.487}
          y2={16.597}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint8_linear_3_2307"
          x1={10.324}
          y1={0.897}
          x2={15.744}
          y2={0.897}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint9_linear_3_2307"
          x1={16.234}
          y1={0.916}
          x2={21.582}
          y2={0.916}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint10_linear_3_2307"
          x1={14.145}
          y1={17.078}
          x2={19.935}
          y2={17.078}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint11_linear_3_2307"
          x1={17.344}
          y1={19.213}
          x2={23.135}
          y2={19.213}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint12_linear_3_2307"
          x1={14.549}
          y1={19.221}
          x2={17.432}
          y2={19.221}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="communication_svg__paint13_linear_3_2307"
          x1={19.883}
          y1={17.067}
          x2={22.757}
          y2={17.067}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgCommunication;
