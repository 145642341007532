import React, { useContext, useState, useEffect } from "react";
import IconSelect from "../iconSelect/IconSelect";
import { AppContext } from "../../context/AppContext";
import { Rate } from "antd";
import "antd/dist/antd.css";
import useSound from "use-sound";
import soundTwo from "../../assets/sounds/soundTwo.wav";
import axios from "../../config/axios-instance";
import qs from "qs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const InputRating = () => {
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [play] = useSound(soundTwo);
  const [currentHeading, setCurrentHeading] = useState("");
  const [currentKey, setCurrentKey] = useState();
  const ratingToSet = ["1star", "2star", "3star", "4star", "5star"];
  const [responseOptions, setResponseOptions] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  console.log(currentKey);
  const HandleOnChange = (value) => {
    setUserInfo({
      ...userInfo,
      rating: ratingToSet[value - 1],
      progress: 63,
      currentKey: currentKey,
    });
  };

  const onClick = (IndustryType) => {
    setUserInfo({
      ...userInfo,
      rating: IndustryType,
      progress: 63,
      currentKey: currentKey,
    });
  };

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/get_by_previous_option",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        q_number: "3",
        previous_op: userInfo.business_type,
        industry: userInfo.industry,
      }),
    })
      .then(function (response) {
        let industrySubTypeOptions = Object.fromEntries(
          Object.entries(response.data).filter(([key]) =>
            key.includes("q_option_")
          )
        );
        if (industrySubTypeOptions.q_option_1 !== "NA") {
          console.log(industrySubTypeOptions.q_option_1);
          setCurrentHeading(response.data.current_q);
          setCurrentKey(response.data.current_key);
          setResponseOptions(industrySubTypeOptions);
          setIsLoaded(true);
        } else {
          setUserInfo({
            ...userInfo,
            rating: "NA",
            progress: 63,
            currentKey: "rating",
          });
          setIsLoaded(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [userInfo, setUserInfo]);

  var found = Object.keys(responseOptions).filter(function (key) {
    return responseOptions[key] === "1star";
  });

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center w-full py-9">
        <Spin tip="Loading..." indicator={antIcon} />
      </div>
    );
  } else {
    if (found.length) {
      return (
        <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
          <div className="text-lg md:text-xl">{currentHeading}</div>
          <div className="flex">
            <Rate
              allowClear={false}
              onChange={(value) => {
                HandleOnChange(value);
                play();
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
          <div className="text-lg md:text-xl">{currentHeading}</div>
          <div className="flex h-48 pt-3 overflow-x-auto">
            {Object.values(responseOptions).map(function (element, index) {
              return (
                <div key={index}>
                  <button
                    onClick={() => {
                      play();
                      onClick(element);
                    }}
                  >
                    <IconSelect name={element} />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
};

export default InputRating;
