import * as React from "react";

function SvgRestaurant({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 33"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3.878 32.004a1.211 1.211 0 00-.142-.059c-.881-.25-1.419-.945-1.419-1.862-.004-1.832 0-3.664 0-5.497 0-4.165.002-8.33.006-12.495 0-.225-.058-.341-.263-.455C.724 10.898.024 9.756.011 8.234-.009 5.743.005 3.255.006.767c0-.473.25-.765.637-.757.387.009.611.292.611.752v6.601H2.38v-.322V.857a2.161 2.161 0 01.008-.312.625.625 0 01.599-.537.625.625 0 01.633.534c.01.104.013.208.009.312v6.508h1.125V.855a2.15 2.15 0 01.01-.311.625.625 0 01.599-.536.625.625 0 01.631.536c.011.103.014.207.01.311v6.507h1.124V.885a2.763 2.763 0 01.006-.311.625.625 0 01.604-.566.626.626 0 01.635.568c.006.093.008.187.005.281v7.215c0 1.626-.698 2.839-2.138 3.605-.203.109-.175.25-.175.41v17.93c0 .988-.51 1.662-1.45 1.93a.808.808 0 00-.112.053l-.624.004zM1.277 8.644c.206.989.739 1.655 1.636 2.02.585.238.649.315.649.937v18.275c0 .574.207.869.614.875.406.007.636-.296.636-.885V11.591c0-.604.07-.697.63-.918a2.553 2.553 0 001.586-1.73c.022-.1.04-.199.05-.3H1.277zM29.81 32.002c-.318-.167-.673-.287-.948-.51-.454-.365-.617-.888-.616-1.472.005-2.922 0-5.85.008-8.775a.701.701 0 00-.238-.57 24.219 24.219 0 01-.994-.992 3.111 3.111 0 01-.895-2.2c-.006-2.831-.072-5.665.018-8.493.116-3.66 1.732-6.58 4.694-8.743.522-.382.807-.325 1.159.198V30.44c-.218.824-.738 1.345-1.562 1.562h-.625zm.936-30.08c-.094.075-.135.106-.172.14-.092.083-.187.167-.274.255-1.91 1.935-2.9 4.264-2.922 6.982-.024 2.696-.004 5.394-.006 8.09a2.053 2.053 0 00.626 1.514c.39.39.772.789 1.174 1.168a1.014 1.014 0 01.334.802c-.009 2.999-.005 5.997-.005 8.996 0 .584.22.892.638.882.417-.009.612-.303.612-.877V1.922h-.005z" />
      <path d="M15.985 24.876a8.89 8.89 0 01-8.856-8.904 8.873 8.873 0 0117.747.066 8.888 8.888 0 01-8.89 8.838zM8.38 16.01a7.624 7.624 0 1015.246-.16 7.624 7.624 0 00-15.246.16z" />
      <path d="M16.29 27.953c-2.6-.023-4.71-.63-6.649-1.836-.26-.163-.43-.374-.372-.693.051-.282.23-.485.522-.5a.98.98 0 01.522.144c1.85 1.137 3.858 1.701 6.028 1.617 3.117-.12 5.704-1.372 7.764-3.712.11-.125.21-.26.327-.378a.617.617 0 01.948.788c-.173.233-.36.456-.56.667-2.388 2.574-5.35 3.846-8.53 3.903zM15.524 4.055c3.793.06 6.647 1.24 9.004 3.601.12.114.214.252.275.405a.578.578 0 01-.28.695.607.607 0 01-.752-.093c-.334-.301-.642-.626-.987-.908-2.269-1.85-4.87-2.628-7.785-2.378-1.276.115-2.52.46-3.673 1.021-.047.023-.093.048-.141.068-.373.16-.72.046-.873-.284a.591.591 0 01.325-.83c.835-.333 1.67-.707 2.54-.926.91-.233 1.862-.301 2.347-.371zM8.641 24.018a.603.603 0 01-.594.626.656.656 0 01-.668-.636.628.628 0 01.6-.629.651.651 0 01.662.64zM10.258 15.792c.19-2.313 1.286-4.013 3.397-5.023.389-.187.745-.071.902.267.157.338.017.668-.375.86-1.697.828-2.587 2.184-2.672 4.067-.02.406-.273.672-.632.668-.359-.004-.61-.286-.617-.683-.004-.055-.003-.104-.003-.156zM15.984 11.5a.613.613 0 01-.624-.635.62.62 0 01.62-.611.634.634 0 01.645.621.626.626 0 01-.64.625z" />
    </svg>
  );
}

export default SvgRestaurant;
