import * as React from "react";

function SvgCustomerRelationship({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M22.45 32c-.727-.159-1.476-.254-2.18-.485-3.905-1.283-6.374-5.083-5.934-9.086.214-1.953 1.02-3.634 2.396-5.063.043-.045.084-.091.133-.132.218-.193.492-.201.676-.023.183.179.198.432 0 .661-.218.252-.457.487-.664.744-3.64 4.562-.91 11.4 4.928 12.34a8.1 8.1 0 003.138-.094c1.03-.235 2-.67 2.855-1.277a7.808 7.808 0 002.122-2.26 7.625 7.625 0 001.056-2.89c.644-4.278-2.54-8.305-6.93-8.774a8.04 8.04 0 00-4.646.866c-.055.03-.111.06-.169.083-.28.12-.525.053-.655-.18a.427.427 0 01.208-.626c.525-.26 1.065-.491 1.617-.69 1.223-.414 2.488-.52 3.804-.372v-3.723h-.76v.446c0 .324-.114.589-.424.737-.296.142-.568.075-.825-.114-1.129-.83-2.263-1.652-3.39-2.487-.454-.337-.458-.868-.007-1.202 1.135-.84 2.277-1.669 3.414-2.505.249-.182.514-.24.8-.107.285.133.422.387.43.7v.496c.132.006.243.015.354.015h3.385c.764 0 1.146.374 1.146 1.122 0 2.629-.002 5.257-.005 7.885a.566.566 0 00.254.512c1.944 1.515 3.083 3.48 3.367 5.905.014.08.033.159.056.237v1.361c-.02.088-.044.177-.058.266-.533 3.601-2.552 6.034-6.048 7.269-.655.231-1.37.3-2.057.445h-1.386zm.032-20.871c.01-.11.016-.157.018-.205.025-.615.272-.853.897-.858h.916c.599.007.866.269.869.854v3.935l2.172.81V7.927h-3.817c-.805 0-1-.18-1.048-.965 0-.015-.023-.028-.057-.066l-2.866 2.1 2.916 2.134z"
        fill="url(#customerRelationship_svg__paint0_linear_3_2241)"
      />
      <path
        d="M7.776 17.637v3.79h.766c0-.188-.009-.365 0-.542.04-.638.67-.96 1.183-.584 1.172.859 2.335 1.731 3.488 2.617.41.313.407.85-.006 1.165a246.055 246.055 0 01-3.489 2.616c-.514.376-1.142.044-1.182-.597-.01-.175 0-.352 0-.562H4.84c-.808 0-1.182-.373-1.182-1.17v-4.296c0-.401.246-.635.581-.552.3.074.37.304.368.582v4.473c.14.006.254.016.365.016h3.414c.88 0 1.054.158 1.12 1.088l2.911-2.179-2.884-2.17c-.178 1.012-.247 1.07-1.28 1.07h-.57c-.6-.008-.87-.272-.872-.868v-4.028l-2.197-.837v.967c0 .37-.179.591-.468.591-.289 0-.477-.215-.479-.58 0-.474.011-.948-.01-1.42a.544.544 0 00-.178-.359C1.143 14.005-.037 11.584 0 8.601.055 4.47 3.24.797 7.319.125c4.815-.792 9.243 2.253 10.222 7.022.988 4.823-2.36 9.66-7.236 10.405-.76.116-1.551.062-2.328.084-.062.003-.123.001-.2.001zm8.979-8.81a7.902 7.902 0 00-2.324-5.575A7.919 7.919 0 008.845.942 7.924 7.924 0 003.26 3.26 7.908 7.908 0 00.943 8.839 7.903 7.903 0 005.84 16.14c.961.396 1.991.6 3.031.598a7.918 7.918 0 005.579-2.328 7.9 7.9 0 002.305-5.583z"
        fill="url(#customerRelationship_svg__paint1_linear_3_2241)"
      />
      <path
        d="M23.11 16.571a6.501 6.501 0 014.663 2.012 7.007 7.007 0 011.941 4.838c.005 3.778-2.98 6.874-6.62 6.865a6.49 6.49 0 01-4.62-2.04 6.993 6.993 0 01-1.902-4.824 6.992 6.992 0 011.914-4.818 6.488 6.488 0 014.625-2.03v-.003zm4.581 10.275c1.509-2 1.604-5.43-.675-7.684a5.539 5.539 0 00-3.971-1.627 5.548 5.548 0 00-3.935 1.722c-2.234 2.312-2.023 5.714-.577 7.581.052-1.608.829-2.561 2.345-2.848-1.094-1.798-1.003-3.37.257-4.52a2.95 2.95 0 011.946-.767 2.945 2.945 0 011.961.726c1.3 1.138 1.408 2.7.311 4.55 1.728.57 2.198 1.134 2.338 2.865v.002zm-2.071 1.906c.392-.276.716-.495 1.026-.731a.355.355 0 00.094-.248c.009-.394.017-.79 0-1.184-.039-.928-.702-1.608-1.59-1.615a230.242 230.242 0 00-4.071 0c-.902.006-1.573.702-1.6 1.638-.01.384-.005.771 0 1.153 0 .078.015.19.064.229.326.246.66.477 1.053.753 0-.41-.009-.718 0-1.027.014-.33.198-.52.474-.514.275.005.441.192.447.533.007.374.008.748 0 1.121-.004.171.05.243.213.284a5.55 5.55 0 002.745 0c.181-.045.228-.133.223-.31-.01-.309 0-.618 0-.928v-.224c.022-.296.181-.47.437-.479.255-.008.46.165.477.472.022.325.008.652.008 1.075v.002zm-2.503-4.745c.414-.001.819-.13 1.163-.37.344-.24.611-.58.769-.977.157-.398.198-.835.117-1.256a2.201 2.201 0 00-.576-1.112 2.073 2.073 0 00-1.073-.594 2.024 2.024 0 00-1.21.126c-.382.165-.71.444-.939.802a2.226 2.226 0 00.268 2.743c.393.406.926.635 1.481.635v.003z"
        fill="url(#customerRelationship_svg__paint2_linear_3_2241)"
      />
      <path
        d="M8.853 15.428a6.593 6.593 0 01-6.567-6.57A6.59 6.59 0 018.88 2.285a6.59 6.59 0 016.547 6.586 6.587 6.587 0 01-6.575 6.556zm4.56-3.292c1.56-1.992 1.593-5.451-.916-7.592-2.403-2.052-6.075-1.685-8.05.788-1.916 2.4-1.314 5.479-.144 6.76.135-1.605.55-2.09 2.323-2.708-1.089-1.723-.985-3.24.288-4.34a3.002 3.002 0 013.867-.017c1.292 1.086 1.4 2.583.309 4.364 1.722.543 2.198 1.098 2.323 2.745zm-7.06 1.818c0-.376-.006-.656 0-.943.01-.343.178-.533.462-.534.283 0 .45.185.456.533.007.348.006.695 0 1.043 0 .152.03.239.201.28a5.706 5.706 0 002.762 0c.171-.042.203-.127.2-.28a29.302 29.302 0 010-1.043c.007-.348.17-.534.457-.533.285 0 .453.192.463.534.007.284 0 .568 0 .907.174-.095.3-.174.435-.238.504-.246.807-.589.684-1.198-.04-.196 0-.41-.009-.612-.044-.855-.697-1.52-1.539-1.53a211.15 211.15 0 00-4.143 0 1.51 1.51 0 00-1.505 1.314c-.062.473-.033.959-.038 1.439a.215.215 0 00.073.156c.331.224.659.442 1.048.705h-.007zm2.5-4.543a2.087 2.087 0 002.085-2.088 2.1 2.1 0 00-2.068-2.08 2.104 2.104 0 00-2.102 2.079A2.088 2.088 0 008.86 9.41h-.007z"
        fill="url(#customerRelationship_svg__paint3_linear_3_2241)"
      />
      <defs>
        <linearGradient
          id="customerRelationship_svg__paint0_linear_3_2241"
          x1={10.964}
          y1={18.998}
          x2={35.1}
          y2={18.998}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="customerRelationship_svg__paint1_linear_3_2241"
          x1={-3.321}
          y1={13.573}
          x2={20.814}
          y2={13.573}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="customerRelationship_svg__paint2_linear_3_2241"
          x1={14.107}
          y1={23.502}
          x2={32.014}
          y2={23.502}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="customerRelationship_svg__paint3_linear_3_2241"
          x1={-0.179}
          y1={8.928}
          x2={17.729}
          y2={8.928}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgCustomerRelationship;
