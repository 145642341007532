import * as React from "react";

function SvgHomestays({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M32 12.787c-.404.43-.804.863-1.214 1.287-.334.344-.675.678-1.013 1.015-.445.444-.555.468-1.14.23v4.802c0 .462-.316.767-.712.706-.327-.05-.533-.31-.534-.698v-4.054c0-.5-.009-1 .005-1.5a.516.516 0 00-.22-.472 7141.324 7141.324 0 01-10.3-8.035c-.27-.21-.542-.416-.834-.647l-1.74 1.356c-3.13 2.444-6.26 4.886-9.39 7.325a.5.5 0 00-.229.435c.007 5.291.008 10.583.005 15.875v.258h1.75v-5.625a2.425 2.425 0 01.006-.28.62.62 0 011.236.007c.008.114.01.228.006.342v5.561h19.703V25.2v-.25c.01-.405.257-.678.615-.683.357-.005.624.276.631.698v5.724h.306c.739 0 1.477.017 2.214-.006.386-.012.667.105.849.443v.374c-.184.337-.465.443-.849.443-10.081-.008-20.163-.01-30.244-.006a2.4 2.4 0 01-.28-.006.62.62 0 01.008-1.236c.134-.013.27-.006.405-.006h2.367v-15.4c-.34.243-.65.258-.93-.019-.74-.732-1.477-1.463-2.204-2.207-.312-.318-.276-.684.062-.974.056-.047.114-.091.172-.136l11.031-8.611c1.434-1.117 2.87-2.23 4.307-3.342h.374c.108.077.22.152.326.233l4.862 3.796c.561.438 1.124.874 1.737 1.351V3.082c0-.554.22-.773.777-.774h3.303c.572 0 .783.216.783.798 0 1.912.006 3.823-.006 5.734a.629.629 0 00.283.565c1.08.828 2.153 1.665 3.217 2.512.176.156.343.323.5.5v.37zM3.036 13.982c.073-.056.156-.118.237-.182 4.062-3.17 8.124-6.34 12.186-9.513.483-.377.667-.377 1.15 0l11.718 9.15c.244.19.49.379.701.542l1.364-1.354L16.034 1.417c-4.8 3.748-9.586 7.485-14.36 11.21l1.362 1.355zM24.417 3.57a.472.472 0 00-.023.083c0 .624.043 1.25-.017 1.87-.06.619.14 1.017.662 1.356.583.38 1.114.851 1.703 1.311V3.57h-2.325z"
        fill="currentColor"
      />
      <path
        d="M22.645 20.225v5.798c0 .583-.212.798-.783.798H10.204c-.559 0-.782-.22-.782-.768v-11.69c0-.538.222-.765.75-.765h11.72c.53 0 .75.226.75.766.003 1.954.004 3.907.003 5.861zm-7.258-.653v-4.703h-4.702v4.703h4.702zm6-4.705h-4.705v4.701h4.702l.003-4.701zm-10.705 10.69h4.708v-4.702h-4.708v4.702zm6-4.71v4.701h4.702v-4.701h-4.702zM7.057 21.75a.617.617 0 01.614.614.622.622 0 11-1.244-.018.616.616 0 01.63-.596zM28.63 22.703a.622.622 0 11-.607-.62.629.629 0 01.607.62z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHomestays;
