import React, { useState, useEffect } from "react";
import axios from "../config/axios-instance";
import qs from "qs";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import SvgDigitalLogo from "../assets/logoComponent/DigitalLogo";

const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [otp, setOtp] = useState("");
  const [edit, setEdit] = useState(false);
  const [editNumber, setEditNumber] = useState("");
  const [seconds, setSeconds] = React.useState(25);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("0");
    }
  }, [seconds]);

  const handleChange = (e) => {
    setOtp(e.target.value.replace(/\D/g, ""));
  };

  const handleOnclick = () => {
    if (!otp) {
      alert("please enter valid OTP");
    }
  };

  const handleNumberChange = (e) => {
    e.preventDefault();
    if (editNumber) {
      if (editNumber.length === 10) {
        setEditNumber(e.target.value);
        location.state.number = editNumber;
        setEdit(false);
      }
    }
  };

  const handleResend = () => {
    console.log("get otp clicked", location.state.number);
    axios({
      method: "post",
      url: "/login/sendno",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        phone_number: location.state.number,
      }),
    })
      .then(function (response) {
        console.log(response.data[0].jwt);
        localStorage.setItem("jwt", response.data[0].jwt);
      })
      .catch(function (error) {
        console.log(error);
      });
    setSeconds(25);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(
      "veryfy otponClick={handleOtp} clicked",
      otp,
      localStorage.getItem("jwt")
    );

    axios({
      method: "post",
      url: "/login/sendotp",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        otp: otp,
        jwt: localStorage.getItem("jwt"),
      }),
    })
      .then(function (response) {
        localStorage.setItem("authorization_token", response.data.token);
        navigate("/onboarding");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  if (location.state === null || !location.state.redirect) {
    return <Navigate to="/" />;
  }
  return (
    <div className=" min-h-screen flex justify-center items-center bg-gray-100">
      <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white">
        <div className=" flex justify-center items-center  text-9xl">
          <SvgDigitalLogo />
        </div>
        <div className="mt-20">
          <div className="text-lg md:text-xl text-center">
            Please enter the verification code we’ve sent you on
          </div>
        </div>

        <div className="mt-2 flex">
          {edit ? (
            <form onSubmit={handleNumberChange} className="mt-2 flex">
              <input
                className="m-2 w-36 placeholder-gray-500 placeholder-opacity-30 border-b-2 border-black text-2xl tracking-widest "
                type="text"
                maxLength="10"
                value={editNumber}
                placeholder={location.state.number}
                onChange={(e) =>
                  setEditNumber(e.target.value.replace(/\D/g, ""))
                }
              />
              <button
                className="text-lg md:text-xl text-blue-500 font-semibold ml-4"
                type="submit"
              >
                Ok
              </button>
            </form>
          ) : (
            <div className="mt-2 flex">
              <div className="text-lg md:text-xl  ">
                {location.state.number}
              </div>
              <button
                className="text-lg md:text-xl text-blue-500 font-semibold ml-4"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className=" flex justify-center items-center mt-12">
            <div id="otp" className="flex justify-center w-1/2">
              <input
                className="m-2 w-56
                text-center border-b-2 border-blue-600 text-4xl tracking-otp px-4"
                type="text"
                id="first"
                maxLength="4"
                value={otp}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className=" flex flex-row-reverse mb-12">
            <div className=" md:text-lg">
              00:{seconds < 10 ? `0${seconds}` : seconds}
            </div>
            <button
              className="md:text-lg mx-4 text-blue-600"
              type="button"
              disabled={seconds > 0}
              onClick={() => handleResend()}
            >
              Resend
            </button>
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={handleOnclick}
              type="submit"
              className="bg-blue-500 text-white text-xl tracking-wider w-1/2 py-4 rounded-full hover:bg-blue-600 font-semibold uppercase"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Otp;
