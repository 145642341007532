import * as React from "react";

function SvgLodge({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M31.998 18.193c-.247.624-.808.461-1.319.504v8.393c0 .721-.189.91-.91.91H2.205c-.697 0-.893-.196-.893-.897v-8.709c-.156.079-.282.152-.412.208-.325.14-.657.038-.817-.243-.16-.282-.09-.621.204-.828.204-.143.426-.261.642-.387 1.36-.79 2.722-1.58 4.084-2.368.139-.079.24-.15.24-.35-.01-2.63-.006-5.259-.007-7.887 0-.04-.007-.08-.015-.158-.134.071-.248.136-.366.193-.33.157-.684.067-.847-.22-.162-.288-.084-.634.217-.853.069-.047.14-.09.213-.13C7.433 3.64 10.414 1.906 13.395.17c.376-.22.688-.23 1.069-.007 2.974 1.743 5.956 3.478 8.944 5.203.1.054.197.117.288.187a.608.608 0 01.156.787.613.613 0 01-.729.276 4.358 4.358 0 01-.475-.225v5.578h.358c2.72 0 5.438.004 8.157 0 .392 0 .69.114.837.504l-.002 5.72zM2.56 26.718h14.862c.004-.072.01-.123.01-.174 0-2.89.001-5.781.005-8.67 0-.19-.078-.277-.222-.363a1867.128 1867.128 0 01-7-4.061c-.17-.1-.289-.08-.45.012a1601.98 1601.98 0 01-6.975 4.045.413.413 0 00-.235.42c.008 2.115.005 4.23.005 6.345v2.446zm18.772-14.745a.55.55 0 00.03-.1c0-2.02.006-4.04 0-6.058a.4.4 0 00-.181-.279c-2.349-1.372-4.7-2.736-7.055-4.092a.495.495 0 00-.415.016c-2.338 1.344-4.67 2.701-7.006 4.05a.362.362 0 00-.213.368c.012.63.005 1.26.005 1.885v1.344h.4c.5 0 1-.012 1.5.008.41.016.672.293.659.646-.014.352-.267.58-.672.6-.186.01-.374 0-.562 0h-1.3v3.531c1-.578 1.967-1.129 2.923-1.698.259-.155.556-.233.857-.226 3.548.006 7.099.007 10.652.004l.378.001zm8.083 6.711h-9.201c-.513 0-1.045.096-1.515-.314v5.854h10.71V21.13h-.355c-.678 0-1.354.005-2.031 0-.486-.003-.774-.241-.778-.629-.004-.388.276-.63.766-.63.666-.003 1.33 0 2 0h.406l-.002-1.188zm-.012 8.051V25.53H18.707v1.206h10.697zm-8.64-13.49v4.16h2.464v-4.16h-2.465zm6.21 4.165v-4.153H24.52v4.153h2.454zm3.75 0v-4.164h-2.452v4.164h2.452zm-11.251-.034v-4.129H17c0 .872 0 1.731.005 2.584a.243.243 0 00.108.168c.774.458 1.555.91 2.36 1.377zm-3.737-4.128H12.36l3.376 1.96v-1.96z"
        fill="currentColor"
      />
      <path
        d="M10.019 18.307h2.34c.54 0 .765.226.766.761v4.738c0 .543-.235.782-.783.783-1.561.005-3.122.005-4.682 0-.548 0-.783-.243-.783-.787-.003-1.569-.003-3.137 0-4.706 0-.56.223-.787.772-.787.788-.008 1.579-.002 2.37-.002zM9.35 20.81v-1.226h-1.2v1.226h1.2zm1.292-1.229v1.217h1.217v-1.217h-1.217zm-1.286 3.738v-1.216H8.144v1.215h1.212zm2.505-1.224H10.65v1.221h1.21l.002-1.221zM11.899 7.918c0-.55-.005-1.109 0-1.662.004-.554.224-.773.783-.776.834-.004 1.667-.004 2.496 0 .558 0 .778.218.783.777.006 1.11.006 2.218 0 3.326 0 .556-.226.773-.783.776-.835.006-1.667.006-2.497 0-.556 0-.773-.22-.782-.779-.01-.559 0-1.108 0-1.662zm1.27-1.164v2.322h1.523V6.754H13.17zM10.246 10.355a.612.612 0 01-.613-.617.619.619 0 01.614-.617.612.612 0 01.613.617.619.619 0 01-.614.617zM25.047 19.883a.618.618 0 11.026 1.239.623.623 0 01-.587-.377.62.62 0 01.56-.862z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLodge;
