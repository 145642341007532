import * as React from "react";

function SvgSmart({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.209.003c.459.181.613.509.604.95-.018.862 0 1.726-.008 2.587-.009.606-.53.967-1.087.77a.757.757 0 01-.379-.262.646.646 0 01-.134-.414c-.011-.967-.007-1.935 0-2.902 0-.369.257-.584.594-.732l.41.003z"
        fill="url(#smart_svg__paint0_linear_3_2266)"
      />
      <path
        d="M15.862 31.99c-.687.027-1.515.02-2.281-.316-1.523-.667-2.328-1.78-2.375-3.312-.033-1.061.025-2.123-.252-3.172-.43-1.628-1.316-3.012-2.548-4.252-2.654-2.67-3.493-5.781-2.28-9.212 1.212-3.43 3.848-5.607 7.713-6.403C19.725 4.11 25.5 7.79 26.293 13.213c.437 2.98-.484 5.61-2.748 7.812-1.397 1.359-2.343 2.91-2.619 4.75-.126.851-.086 1.723-.123 2.588-.059 1.372-.711 2.43-2.013 3.142a4.115 4.115 0 01-1.896.486c-.295.005-.593-.001-1.032-.001zm1.875-7.262c.58 0 1.102.01 1.627-.01.078 0 .19-.128.215-.214.497-1.721 1.47-3.182 2.805-4.477 1.909-1.847 2.693-4.065 2.325-6.574-.684-4.656-5.63-7.775-10.664-6.69-6.399 1.378-9.12 8.424-4.632 13.045 1.368 1.408 2.47 2.907 2.988 4.737.019.07.117.172.183.174.547.014 1.094.008 1.686.008-.459-2.685-.902-5.331-1.351-7.995-.29 0-.54.004-.79 0-.558-.014-.923-.305-.923-.733 0-.429.367-.732.918-.732 2.588-.005 5.177-.005 7.765 0 .55 0 .918.314.912.742-.007.428-.368.706-.9.722-.262.007-.523 0-.813 0l-1.35 7.996v.001zm-3.168-7.977a1.164 1.164 0 000 .168c.424 2.507.853 5.015 1.285 7.521.034.099.082.193.143.28.054-.09.139-.173.155-.266.368-2.115.73-4.231 1.085-6.35.076-.447.146-.894.221-1.356l-2.89.003zm4.63 9.448h-6.46l.078 1.419h6.382v-1.419zm-6.244 2.905c.357.762.928 1.278 1.778 1.369.84.085 1.688.085 2.528 0 .864-.088 1.438-.607 1.795-1.373l-6.1.004z"
        fill="url(#smart_svg__paint1_linear_3_2266)"
      />
      <path
        d="M7.217 7.196c-.038.394-.19.623-.506.745-.332.129-.632.058-.883-.167-.419-.37-.827-.751-1.24-1.124-.643-.585-1.285-1.159-1.916-1.746-.377-.35-.357-.81.023-1.098a.809.809 0 01.538-.177.798.798 0 01.522.213c1.086.97 2.161 1.95 3.227 2.939.133.122.183.316.235.415z"
        fill="url(#smart_svg__paint2_linear_3_2266)"
      />
      <path
        d="M25.729 8.006c-.455-.014-.706-.154-.855-.431-.15-.278-.092-.58.176-.83.529-.49 1.067-.973 1.6-1.458.503-.457 1.002-.918 1.507-1.37.4-.358.87-.378 1.212-.06.343.32.314.732-.067 1.082-1.025.937-2.05 1.873-3.09 2.798-.153.137-.375.21-.483.269z"
        fill="url(#smart_svg__paint3_linear_3_2266)"
      />
      <path
        d="M29.577 15.265c.527 0 1.054-.017 1.58.004.69.028 1.059.627.709 1.121a.799.799 0 01-.318.257.89.89 0 01-.417.077c-1.018.005-2.037.01-3.056 0-.535-.007-.877-.315-.87-.741.006-.426.353-.71.894-.72.492-.01.985 0 1.477 0v.002z"
        fill="url(#smart_svg__paint4_linear_3_2266)"
      />
      <path
        d="M2.402 15.262c.493 0 .985-.005 1.478 0 .564.009.918.296.918.73s-.352.73-.918.735c-.985.007-1.97.007-2.954 0-.56-.005-.923-.301-.926-.73-.003-.429.36-.725.918-.735.5-.005.992 0 1.484 0z"
        fill="url(#smart_svg__paint5_linear_3_2266)"
      />
      <defs>
        <linearGradient
          id="smart_svg__paint0_linear_3_2266"
          x1={14.895}
          y1={2.205}
          x2={17.096}
          y2={2.205}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="smart_svg__paint1_linear_3_2266"
          x1={1.678}
          y1={18.69}
          x2={30.042}
          y2={18.69}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="smart_svg__paint2_linear_3_2266"
          x1={1.496}
          y1={5.84}
          x2={8.06}
          y2={5.84}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="smart_svg__paint3_linear_3_2266"
          x1={23.89}
          y1={5.843}
          x2={30.451}
          y2={5.843}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="smart_svg__paint4_linear_3_2266"
          x1={26.305}
          y1={16.002}
          x2={32.839}
          y2={16.002}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="smart_svg__paint5_linear_3_2266"
          x1={-0.9}
          y1={16.004}
          x2={5.637}
          y2={16.004}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgSmart;
