import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import useSound from "use-sound";
import soundOne from "../../assets/sounds/soundOne.wav";

const InputGoals = () => {
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [play] = useSound(soundOne);

  let goal = userInfo.impact;

  const onClick = (e) => {
    if (!goal.includes(e.target.value)) {
      goal.push(e.target.value);
      setUserInfo({ ...userInfo, impact: goal });
    } else {
      const index = goal.indexOf(e.target.value);
      if (index > -1) {
        goal.splice(index, 1);
      }
      setUserInfo({ ...userInfo, impact: goal });
    }
  };

  const handleButtonColor = (button) => {
    if (goal.includes(button)) {
      return "bg-blue-700 text-white rounded-md my-1 p-1 px-2 transform hover:scale-110 transition duration-200 ease-in-out";
    } else {
      return "bg-blue-100 rounded-md my-1 p-1 px-2 transform hover:scale-110 transition duration-200 ease-in-out hover:bg-blue-700 hover:text-white";
    }
  };

  const handleSubmit = () => {
    setUserInfo({ ...userInfo, progress: "100" });
  };

  var options = [
    { value: "Brand Building", label: "Brand Building" },
    { value: "Revenue Growth", label: "Revenue Growth" },
    { value: "Market Expansion", label: "Market Expansion" },
    { value: "Profitability", label: "Profitability" },
    { value: "Distribution Efficiency", label: "Distribution Efficiency" },
    { value: "Productivity", label: "Productivity" },
    { value: "Financing", label: "Financing" },
  ];

  return (
    <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
      <div className="tracking-wide">
        Which of the following do you want to achieve the most? (select top 3)
      </div>
      <div className="flex justify-center items-center w-full mt-4">
        <div className="flex flex-col">
          {options.map((item, index) => {
            return (
              <button
                key={index}
                className={`${handleButtonColor(item.value)} `}
                value={item.value}
                onClick={(e) => {
                  play();
                  onClick(e);
                }}
              >
                {item.label}
              </button>
            );
          })}
          <button
            className="bg-blue-600 rounded-md mt-10 font-semibold p-1 px-2 transform hover:scale-125 transition duration-200 ease-in-out hover:bg-blue-700 text-white"
            onClick={handleSubmit}
            disabled={goal.length > 3}
          >
            Create Journey
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputGoals;
