import * as React from "react";

function SvgOnlinePresence({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M32 19.85c-.157.602-.35 1.186-.769 1.677-.714.837-1.61 1.257-2.706 1.257h-8.077c.283 1.416.556 2.803.837 4.188.15.738.157.738.9.738.783 0 1.565-.005 2.343 0 .41 0 .64.19.688.534a.587.587 0 01-.476.662c-.134.018-.27.025-.405.02H7.679c-.104 0-.209 0-.313-.007-.348-.03-.594-.284-.593-.604a.597.597 0 01.597-.6c.812-.015 1.624-.008 2.437-.009.753 0 .759 0 .907-.727.28-1.386.556-2.772.84-4.198H3.61c-2.169 0-3.61-1.434-3.61-3.593V3.504C0 1.752 1.126.373 2.805.067c.268-.04.54-.057.81-.05C11.873.016 20.132.033 28.39 0c1.85-.008 3.36 1.296 3.566 2.888.01.03.024.058.042.083L32 19.85zm-1.252-3.236c.005-.11.013-.19.013-.272V3.53a2.64 2.64 0 00-.061-.619c-.266-1.068-1.096-1.672-2.295-1.672H3.59c-.25-.005-.499.021-.742.078-1.013.27-1.612 1.071-1.615 2.176-.006 3.478-.006 6.957 0 10.437 0 .069.014.137.023.227h3.545c.443 0 .72.243.726.609.006.365-.276.626-.738.626-.771.006-1.542 0-2.313 0h-1.22v1.21l29.49.012zm-29.48 1.252c0 .653-.016 1.276.009 1.898.022.208.088.408.195.588.448.853 1.174 1.199 2.126 1.198 8.27-.008 16.54-.01 24.81-.005.125 0 .25 0 .375-.01a2.084 2.084 0 001.462-.742c.766-.874.459-1.92.521-2.927H1.268zm17.926 4.936h-6.385c-.326 1.63-.65 3.248-.976 4.889h8.335c-.328-1.643-.652-3.26-.974-4.89z"
        fill="url(#onlinePresence_svg__paint0_linear_3_2332)"
      />
      <path
        d="M19.715 10.48c.422.419.813.805 1.2 1.195.402.405.4.713-.006 1.123-.36.361-.719.723-1.083 1.08-.364.357-.686.367-1.054.004-.399-.391-.783-.799-1.228-1.253-.336.354-.644.673-.946.998-.204.22-.436.362-.745.272-.31-.09-.4-.337-.463-.612l-1.236-5.375c-.133-.583.22-.957.794-.826 1.833.417 3.665.839 5.496 1.265.587.137.738.674.314 1.11-.332.345-.683.669-1.043 1.018zm-3.328 1.623c.23-.231.418-.423.608-.612.407-.402.717-.403 1.121 0 .392.386.776.776 1.14 1.14l.453-.429c-.387-.385-.773-.763-1.154-1.146-.406-.409-.404-.711 0-1.122.193-.195.387-.391.61-.614l-3.614-.83c.282 1.225.553 2.392.836 3.613z"
        fill="url(#onlinePresence_svg__paint1_linear_3_2332)"
      />
      <path
        d="M18.002 5.3c-.078.185-.102.32-.18.405-.23.267-.48.517-.746.748a.594.594 0 01-.834-.831c.23-.269.478-.52.745-.75a.487.487 0 01.636-.038c.162.117.26.31.379.466z"
        fill="url(#onlinePresence_svg__paint2_linear_3_2332)"
      />
      <path
        d="M15.506 4.8a3.92 3.92 0 01-.042.528.57.57 0 01-.573.476c-.302.006-.558-.166-.594-.481a5.177 5.177 0 01-.007-1.085.579.579 0 01.606-.514.61.61 0 01.578.517c.021.186.032.373.032.56z"
        fill="url(#onlinePresence_svg__paint3_linear_3_2332)"
      />
      <path
        d="M13.7 6.012c-.126.162-.227.355-.384.478a.47.47 0 01-.608-.023 7.383 7.383 0 01-.79-.79c-.19-.227-.147-.497.04-.716.178-.207.455-.325.668-.161.357.273.655.626.97.95.042.043.03.138.044.21l.06.052z"
        fill="url(#onlinePresence_svg__paint4_linear_3_2332)"
      />
      <path
        d="M12.417 10.885c-.47-.012-.783-.531-.518-.897.248-.32.535-.606.855-.852.203-.165.458-.104.662.06.204.166.323.453.181.637-.291.376-.666.688-1.014 1.018-.037.035-.121.025-.166.034z"
        fill="url(#onlinePresence_svg__paint5_linear_3_2332)"
      />
      <path
        d="M11.827 8.417c-.157 0-.313.011-.47 0a.59.59 0 01-.566-.59.571.571 0 01.548-.604c.332-.023.664-.023.996 0a.578.578 0 01.54.581.595.595 0 01-.549.609c-.165.016-.332 0-.498 0l-.001.004z"
        fill="url(#onlinePresence_svg__paint6_linear_3_2332)"
      />
      <path
        d="M6.78 14.175a.6.6 0 01.598.626.608.608 0 11-1.217-.019.602.602 0 01.618-.607z"
        fill="url(#onlinePresence_svg__paint7_linear_3_2332)"
      />
      <path
        d="M16.01 20.925a1.223 1.223 0 01-1.24-1.217 1.23 1.23 0 012.46-.022 1.225 1.225 0 01-1.22 1.24z"
        fill="url(#onlinePresence_svg__paint8_linear_3_2332)"
      />
      <defs>
        <linearGradient
          id="onlinePresence_svg__paint0_linear_3_2332"
          x1={-6}
          y1={14.62}
          x2={37.6}
          y2={14.62}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint1_linear_3_2332"
          x1={12.797}
          y1={10.643}
          x2={22.456}
          y2={10.643}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint2_linear_3_2332"
          x1={15.769}
          y1={5.664}
          x2={18.331}
          y2={5.664}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint3_linear_3_2332"
          x1={14.032}
          y1={4.775}
          x2={15.723}
          y2={4.775}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint4_linear_3_2332"
          x1={11.438}
          y1={5.667}
          x2={14.034}
          y2={5.667}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint5_linear_3_2332"
          x1={11.454}
          y1={9.971}
          x2={13.984}
          y2={9.971}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint6_linear_3_2332"
          x1={10.399}
          y1={7.82}
          x2={13.239}
          y2={7.82}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint7_linear_3_2332"
          x1={5.933}
          y1={14.794}
          x2={7.591}
          y2={14.794}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="onlinePresence_svg__paint8_linear_3_2332"
          x1={14.308}
          y1={19.724}
          x2={17.66}
          y2={19.724}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgOnlinePresence;
