import React, { useState } from "react";
import SvgDigitalLogo from "../assets/logoComponent/DigitalLogo";
import axios from "../config/axios-instance";
import qs from "qs";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);
  const [number, setNumber] = useState("");
  const handleChange = (e) => {
    setNumber(e.target.value.replace(/\D/g, ""));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("get otp clicked", number);
    axios({
      method: "post",
      url: "/login/sendno",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        phone_number: number,
      }),
    })
      .then(function (response) {
        console.log(response.data[0].jwt);
        localStorage.setItem("jwt", response.data[0].jwt);
        setRedirect(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  if (redirect) {
    navigate("otp", { state: { redirect: redirect, number: number } });
  }

  return (
    <div className=" min-h-screen flex justify-center items-center bg-gray-100">
      <div className="shadow-2xl flex flex-col p-10 rounded-lg bg-white">
        <div className=" flex justify-center items-center text-9xl ">
          <SvgDigitalLogo />
        </div>
        <div className=" md:w-4/5 self-center">
          <div className="mt-20">
            <div className="text-3xl font-semibold ">Welcome!</div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mt-2 ">
              <div className="text-lg md:text-xl ">
                Enter your mobile number.
              </div>
              <div className="text-xl mt-10">Phone Number </div>
            </div>
            <div className=" flex justify-center items-center mt-6 mb-16">
              <div id="otp" className="flex justify-center w-1/2">
                <input
                  className="m-2 text-center w-16 border-b-2 border-blue-300 text-2xl"
                  type="text"
                  id="first"
                  maxLength="4"
                  value="+91-"
                  disabled
                />
                <input
                  className="m-2 text-center w-64 md:w-80 border-b-2 border-blue-300 text-3xl"
                  type="text"
                  id="second "
                  maxLength="10"
                  value={number}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-blue-500 text-white text-xl font-medium w-1/2 py-4 rounded-full hover:bg-blue-600 "
                type="submit"
              >
                Get OTP
              </button>
            </div>
          </form>
          <div className="text-center text-gray-400 text-lg mt-8">
            By continuing, I agree to the{" "}
            <span className=" text-blue-600"> Terms of Services </span> &{" "}
            <span className="text-blue-600">Privacy Policy</span>
          </div>
          <div className="text-center pt-6 text-lg">Made with ❤️ in India</div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
