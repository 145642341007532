import React from 'react'
import { FaSkyatlas } from 'react-icons/fa'
import SvgHospitality from '../../assets/iconComponents/Hospitality'
import SvgHealthcare from '../../assets/iconComponents/Healthcare'
import SvgManufacturing from '../../assets/iconComponents/Manufacturing'
import SvgRestaurant from '../../assets/iconComponents/Restaurant'
import {
    TraditionalRestraunt,
    Qsr,
    Pub,
    NightClub,
    FoodTruck,
    FineDining,
    Dessert,
    CloudKitchen,
    CasualDining,
    Cafe,
    Brewerie,
    Bar,
    Bakery
} from '../../assets/industryIcons/restaurantComponents/index'
import {
    ServicedApartment,
    Resorts,
    Lodge,
    Hostels,
    Homestays,
    Heritage,
    BuisnessHotels,
    BudgetHostel,
    Boutique,
    Bnb
} from '../../assets/industryIcons/hospitalityComponents/index'


const IconSelect = ({ name }) => {

    const iconImage = () => {
        switch (name) {
            case 'Restaurants':
                return <SvgRestaurant />
            case 'QSR':
                return <Qsr />
            case 'Pub':
                return <Pub />
            case 'NightClub':
                return <NightClub />
            case 'FoodTrucks':
                return <FoodTruck />
            case 'Fine Dining':
                return <FineDining />
            case 'Desserts, Icecream Parlour':
                return <Dessert />
            case 'Casual Dining':
                return <CasualDining />
            case 'Cafe':
                return <Cafe />
            case 'Brewerie':
                return <Brewerie />
            case 'Bar':
                return <Bar />
            case 'Traditional Resturant':
                return <TraditionalRestraunt />
            case 'Cloud Kitchen':
                return <CloudKitchen />
            case 'Bakery':
                return <Bakery />
            case 'Healthcare':
                return <SvgHealthcare />
            case 'Manufacturing':
                return <SvgManufacturing />
            case 'Hospitality':
                return <SvgHospitality />
            case 'BnBs':
                return <Bnb />
            case 'Concept/Boutique Hotels':
                return <Boutique />
            case 'Budget Hotels':
                return <BudgetHostel />
            case 'Business Hotels':
                return <BuisnessHotels />
            case 'Heritage Hotels':
                return <Heritage />
            case 'Homestays':
                return <Homestays />
            case 'Hostels':
                return <Hostels />
            case 'Lodge':
                return <Lodge />
            case 'Resorts':
                return <Resorts />
            case 'Serviced Apartments':
                return <ServicedApartment />
            default:
                return <FaSkyatlas />
        }
    };

    return (
        <div className="mx-6 w-28" >
            <div className='flex flex-col'>
                <div className='flex items-center justify-center'>
                    <div className='text-5xl p-3 rounded-full bg-blue-300 transform hover:scale-105 transition duration-200 ease-in-out hover:bg-blue-700 hover:text-white w-20 h-20 cursor-pointer flex items-center justify-center'>
                        {iconImage()}
                    </div>
                </div>
                <div className="text-center text-lg h-11">{name}</div>
            </div>

        </div>
    );
};

export default IconSelect;
