import * as React from "react";

function SvgPayment({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M32 11.688v8.622c-.305.846-.927 1.126-1.797 1.12-3.02-.026-6.039-.01-9.059-.01h-.386v.417c0 2.634.004 5.269 0 7.903 0 1.325-.943 2.254-2.27 2.254H16.27c-.393 0-.612-.167-.617-.464-.004-.296.217-.472.606-.473.77 0 1.54.005 2.311 0 .571-.006 1.086-.342 1.18-.884.097-.562.067-1.146.093-1.722H.943v1.13c0 1.003.474 1.478 1.467 1.478h11.775c.322.011.516.184.52.46.004.275-.188.46-.501.476H2.425c-1.531 0-2.422-.892-2.422-2.426V3.612c0-.49-.007-.98 0-1.469A2.183 2.183 0 011.497.116c.106-.036.21-.078.316-.116h17.123c1.434.41 1.857 1.408 1.833 2.696-.049 2.497-.015 4.996-.015 7.494v.387H25.915c.125-.005.25.005.371.03a.457.457 0 01-.064.897 2.602 2.602 0 01-.312.008h-6.711c-.208 0-.417-.007-.625 0-.272.013-.47.153-.49.43-.027.369-.007.741-.007 1.124h12.985v-.865c0-.557-.137-.692-.706-.692h-2.248c-.37 0-.603-.266-.515-.57.078-.274.287-.365.55-.365.688 0 1.376.026 2.061-.007.872-.04 1.492.273 1.796 1.121zM19.811 21.42h-1.08c-1.02-.005-1.593-.57-1.593-1.586v-7.685c0-.996.572-1.568 1.57-1.574h1.1v-6.06H.958V27.48h18.853v-6.06zm-1.736-5.285v3.595c0 .627.124.751.744.751h11.708c.364-.02.537-.188.538-.548.004-1.228 0-2.455 0-3.683a.543.543 0 00-.026-.116H18.074zm1.748-12.585c0-.394-.022-.759.005-1.12.077-1.014-.659-1.509-1.483-1.502-3.966.026-7.931.011-11.896.011H2.233c-.63 0-1.157.37-1.238.97C.922 2.445.98 2.995.98 3.548l18.843.001zm11.229 10.503H18.09v1.107h12.962v-1.107z"
        fill="url(#payment_svg__paint0_linear_3_2357)"
      />
      <path
        d="M10.356 30.222c-.572 0-1.144.007-1.716 0-.37-.006-.575-.226-.525-.54a.423.423 0 01.453-.387c1.207-.006 2.414-.009 3.62 0a.453.453 0 01.476.47c-.007.257-.198.449-.497.455-.604.01-1.207 0-1.81 0v.002z"
        fill="url(#payment_svg__paint1_linear_3_2357)"
      />
      <path
        d="M15.652 16.006c-.012 2.716-2.137 5.048-4.778 5.238-2.856.206-5.277-1.691-5.696-4.478a5.113 5.113 0 01.743-3.558c.202-.326.439-.418.693-.268.255.149.297.429.102.758-1.62 2.741-.102 6.042 3.027 6.583 2.576.445 5.01-1.67 4.96-4.335-.031-1.67-.801-2.945-2.255-3.742-1.433-.786-2.894-.719-4.315.098-.036.02-.07.044-.106.065-.296.167-.562.131-.711-.105-.15-.235-.073-.5.212-.687a4.995 4.995 0 011.962-.764c3.005-.475 5.686 1.502 6.117 4.508.03.227.03.46.045.687z"
        fill="url(#payment_svg__paint2_linear_3_2357)"
      />
      <path
        d="M27.093 19.15c-.405 0-.81.004-1.215 0-.373-.007-.571-.176-.567-.473 0-.285.195-.458.548-.461.83-.006 1.661-.006 2.492 0 .347 0 .548.179.554.458.006.28-.2.47-.566.475-.416.005-.83 0-1.246 0z"
        fill="url(#payment_svg__paint3_linear_3_2357)"
      />
      <path
        d="M10.377 2.71c-.187 0-.375.01-.561 0-.288-.02-.454-.19-.46-.448a.433.433 0 01.436-.47c.393-.02.79-.02 1.184 0a.44.44 0 01.433.476c-.024.26-.167.423-.44.44-.196.012-.394 0-.592 0v.002z"
        fill="url(#payment_svg__paint4_linear_3_2357)"
      />
      <path
        d="M11.794 13.608c.06.026.08.073.08.135-.002.093 0 .185 0 .278 0 .103-.035.137-.138.137h-.747c.03.046.06.082.077.123.044.104.085.21.124.317.01.03.02.04.053.04h.506c.095 0 .13.035.13.13v.292c0 .093-.036.128-.131.128h-.497c-.03 0-.042.007-.048.039a1.222 1.222 0 01-.415.731 1.572 1.572 0 01-.706.335c-.072.015-.144.027-.222.041l.04.046c.516.554 1 1.137 1.483 1.72.03.036.047.073.024.12s-.062.064-.112.064h-.626a.136.136 0 01-.11-.055c-.3-.35-.597-.703-.9-1.05-.233-.265-.472-.524-.711-.783a.237.237 0 01-.073-.183c.005-.112 0-.225.001-.337 0-.088.042-.128.13-.13.19-.002.38 0 .57-.01.215-.015.42-.068.597-.199a.61.61 0 00.234-.348H9.019c-.11 0-.143-.032-.144-.14v-.283c0-.09.037-.126.13-.126h1.357c-.012-.022-.02-.039-.03-.054a.616.616 0 00-.312-.236 1.546 1.546 0 00-.574-.087h-.423c-.113 0-.147-.035-.147-.148 0-.123.002-.246 0-.37-.002-.062.018-.108.076-.136h2.842z"
        fill="url(#payment_svg__paint5_linear_3_2357)"
      />
      <defs>
        <linearGradient
          id="payment_svg__paint0_linear_3_2357"
          x1={-6}
          y1={16.169}
          x2={37.6}
          y2={16.169}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="payment_svg__paint1_linear_3_2357"
          x1={7.253}
          y1={29.762}
          x2={13.462}
          y2={29.762}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="payment_svg__paint2_linear_3_2357"
          x1={3.138}
          y1={16.056}
          x2={17.496}
          y2={16.056}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="payment_svg__paint3_linear_3_2357"
          x1={24.637}
          y1={18.687}
          x2={29.534}
          y2={18.687}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="payment_svg__paint4_linear_3_2357"
          x1={8.968}
          y1={2.251}
          x2={11.77}
          y2={2.251}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="payment_svg__paint5_linear_3_2357"
          x1={8.311}
          y1={15.971}
          x2={12.406}
          y2={15.971}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgPayment;
