import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Otp from "./pages/Otp";
import Landing from "./pages/Landing";
import Loading from "./components/loading/Loading";
import CommunicationStation from "./pages/communications-station";
import OnlinePresenceStation from "./pages/online-presence-station";
import PaymentBankingStation from "./pages/payment-banking-station";
import IndependentIdentityStation from "./pages/independent-identity-station";
import BusinessMgmtStation from "./pages/business-mgmt-station";
import DistributionStation from "./pages/distribution-station";
import GrowthStation from "./pages/growth-station";
import SmartStation from "./pages/smart-station";
import LoanInsuranceStation from "./pages/loan-insurance-station";
import CustomerRelationshipStation from "./pages/customer-relationship-station";
import { AppProvider } from "./context/AppContext";
import Journey from "./pages/Journey";
import SolutionPlot from "./pages/SolutionPlot";

const App = () => {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" exact element={<SignUp />} />
          <Route path="/otp" exact element={<Otp />} />
          <Route path="/onboarding" exact element={<Landing />} />
          <Route path="/loading" exact element={<Loading />} />
          <Route path="/journey" exact element={<Journey />} />
          <Route
            path="/communication-station"
            exact
            element={<CommunicationStation />}
          />
          <Route
            path="/online-presence-station"
            exact
            element={<OnlinePresenceStation />}
          />
          <Route
            path="/payment-banking-station"
            exact
            element={<PaymentBankingStation />}
          />
          <Route
            path="/independent-identity-station"
            exact
            element={<IndependentIdentityStation />}
          />
          <Route
            path="/business-mgmt-station"
            exact
            element={<BusinessMgmtStation />}
          />
          <Route
            path="/distribution-station"
            exact
            element={<DistributionStation />}
          />
          <Route path="/growth-station" exact element={<GrowthStation />} />
          <Route path="/smart-station" exact element={<SmartStation />} />
          <Route
            path="/loan-insurance-station"
            exact
            element={<LoanInsuranceStation />}
          />
          <Route
            path="/customer-relationship-station"
            exact
            element={<CustomerRelationshipStation />}
          />
          <Route path="/solution-plot" exact element={<SolutionPlot />} />
          <Route path="*" exact element={<SignUp />} />
        </Routes>
      </Router>
    </AppProvider>
  );
};

export default App;
