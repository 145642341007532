import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import useSound from "use-sound";
import soundOne from "../../assets/sounds/soundOne.wav";
import axios from "../../config/axios-instance";
import qs from "qs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const InputInvetorySize = () => {
  const [size, setSize] = useState("");
  const [value, setValue] = useState({ buisnessSize: "" });
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [currentKey, setCurrentKey] = useState();
  const [currentHeading, setCurrentHeading] = useState("");
  const [play] = useSound(soundOne);
  const onClick = (e) => {
    setValue({ ...value, buisnessSize: e.target.value });
    setUserInfo({
      ...userInfo,
      inventry: e.target.value,
      progress: "73",
      currentKey: currentKey,
    });
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/get_by_previous_option",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        q_number: "4",
        previous_op: userInfo.rating,
        industry: userInfo.industry,
      }),
    })
      .then(function (response) {
        setCurrentHeading(response.data.current_q);
        setCurrentKey(response.data.current_key);
        let industrySizeOptions = Object.fromEntries(
          Object.entries(response.data).filter(([key]) =>
            key.includes("q_option_")
          )
        );
        setSize(industrySizeOptions);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [userInfo]);

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center w-full py-9">
        <Spin tip="Loading..." indicator={antIcon} />
      </div>
    );
  } else {
    return (
      <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
        <div className="text-xl font-semibold pb-2">Superb!</div>
        <div className="tracking-wide">
          Now let’s understand {userInfo.buisnessName} business a little better.
        </div>
        <div className="text-lg pt-2 font-semibold pb-2">{currentHeading}</div>
        <div className="flex justify-center items-center w-full ">
          <div className="flex mx-2 overflow-x-auto">
            {Object.values(size).map(function (element, index) {
              return (
                <div key={index}>
                  <button
                    className="bg-blue-100 rounded-md m-2 p-1 px-2 transform hover:scale-110 transition duration-200 ease-in-out hover:bg-blue-700 hover:text-white w-20"
                    value={element}
                    onClick={(e) => {
                      play();
                      onClick(e);
                    }}
                  >
                    {element}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default InputInvetorySize;
