import * as React from "react";

function SvgIndependentIdentity({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.293 47.096c-.364-.243-.462-.59-.46-1.022.009-5.902 0-11.805.012-17.706a1.066 1.066 0 00-.317-.806C2.303 25.237.835 22.493.284 19.297-.71 13.53.915 8.584 4.986 4.457c.393-.4.803-.437 1.107-.12.304.316.27.712-.15 1.119-1.8 1.753-3.138 3.82-3.858 6.241-1.67 5.667-.523 10.745 3.533 14.975C9.1 30.303 13.46 31.66 18.406 30.88c5.94-.935 10.835-5.812 11.936-11.813 1.173-6.4-1.732-12.772-7.382-15.835C18.432.776 13.802.773 9.192 3.107c-.135.07-.265.147-.403.21-.426.2-.794.115-.988-.23-.194-.346-.071-.76.35-1A15.217 15.217 0 0112.178.474c3.953-.947 7.75-.482 11.326 1.456 3.486 1.89 5.968 4.739 7.403 8.485 1.343 3.508 1.433 7.087.355 10.674a15.62 15.62 0 01-3.793 6.45 1.156 1.156 0 00-.34.89c.011 5.875 0 11.743.015 17.614 0 .444-.085.807-.462 1.062h-.454a1.687 1.687 0 00-.198-.1c-3.3-1.127-6.603-2.249-9.906-3.367a1.007 1.007 0 00-.604.02c-1.77.606-3.538 1.223-5.302 1.85-1.49.52-2.98 1.058-4.47 1.588h-.455zm20.45-17.945c-4.438 3.26-9.253 4.107-14.496 2.65v11.827c.197-.058.352-.097.504-.15.956-.33 1.93-.614 2.859-1.015.85-.366 1.623-.358 2.475-.007 1.014.417 2.075.717 3.166 1.084v-.555-8.69c0-.741.215-1.08.686-1.085.471-.005.69.336.69 1.075 0 3.097.005 6.192-.008 9.289 0 .324.084.483.403.585 1.094.345 2.176.731 3.263 1.099.14.047.284.081.454.131l.003-16.238zM6.236 45.401c1.102-.384 2.135-.76 3.178-1.098.33-.107.426-.264.425-.614-.013-3.908-.024-7.815.008-11.721 0-.576-.154-.87-.68-1.031a3.396 3.396 0 01-.727-.368c-.732-.445-1.457-.902-2.21-1.37l.006 16.203z"
        fill="url(#independentIdentity_svg__paint0_linear_3_2371)"
      />
      <path
        d="M15.997 28.958c-6.864.016-12.508-5.713-12.518-12.7-.01-6.988 5.6-12.687 12.503-12.7 6.883-.013 12.515 5.694 12.519 12.685.005 6.99-5.618 12.699-12.504 12.715zm11.13-12.722c-.02-6.22-5.02-11.28-11.14-11.278A11.08 11.08 0 008.13 8.27 11.42 11.42 0 004.85 16.24c-.014 6.206 5.081 11.367 11.163 11.307 6.164-.061 11.133-5.118 11.114-11.311z"
        fill="url(#independentIdentity_svg__paint1_linear_3_2371)"
      />
      <path
        d="M11.363 21.856c.123-1.174.263-2.375.363-3.58a.966.966 0 00-.214-.64c-.742-.868-1.516-1.708-2.276-2.561-.567-.642-.393-1.2.437-1.383 1.12-.24 2.245-.46 3.36-.732a.942.942 0 00.534-.4c.591-.998 1.144-2.016 1.718-3.024.414-.724.995-.723 1.41 0 .567 1.008 1.126 2.028 1.716 3.024a.943.943 0 00.536.397c1.114.266 2.239.491 3.36.733.832.179 1.007.734.431 1.382-.768.864-1.549 1.718-2.305 2.595a.844.844 0 00-.186.562c.088 1.053.21 2.102.32 3.152.014.123.032.244.041.366.061.873-.388 1.202-1.17.848-.964-.436-1.93-.862-2.881-1.333a1.16 1.16 0 00-1.14 0c-.977.479-1.972.921-2.966 1.364-.66.3-1.115-.02-1.088-.77zm-.444-6.995c.626.7 1.167 1.356 1.763 1.959.43.433.555.89.46 1.488-.13.836-.183 1.684-.279 2.604.923-.422 1.763-.795 2.591-1.19a1.17 1.17 0 011.081.005c.829.397 1.668.767 2.542 1.166 0-.157.01-.26 0-.363-.08-.775-.148-1.554-.255-2.326-.07-.506.026-.914.394-1.29.551-.565 1.062-1.172 1.589-1.765.073-.094.14-.194.202-.298-.908-.195-1.764-.402-2.624-.55a1.33 1.33 0 01-.617-.25 1.357 1.357 0 01-.424-.519c-.414-.789-.87-1.556-1.349-2.404-.506.895-.978 1.708-1.429 2.534a1.137 1.137 0 01-.842.612c-.904.168-1.798.373-2.803.588z"
        fill="url(#independentIdentity_svg__paint2_linear_3_2371)"
      />
      <defs>
        <linearGradient
          id="independentIdentity_svg__paint0_linear_3_2371"
          x1={-6}
          y1={23.806}
          x2={37.6}
          y2={23.806}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="independentIdentity_svg__paint1_linear_3_2371"
          x1={-1.213}
          y1={16.394}
          x2={32.88}
          y2={16.394}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="independentIdentity_svg__paint2_linear_3_2371"
          x1={6.249}
          y1={15.939}
          x2={25.554}
          y2={15.939}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgIndependentIdentity;
