import * as React from "react";

function SvgLoanInsurance({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M8.906 3.13l-.251-.068-.2.166-.933.776a3.471 3.471 0 01-.312-1.441V1.29a.791.791 0 011.58 0v.516a.759.759 0 001.517 0V1.29a.791.791 0 011.58 0v.516a.759.759 0 001.516 0V1.29a.791.791 0 011.58 0v1.273a3.47 3.47 0 01-.562 1.899l-.128.136L8.906 3.13z"
        stroke="url(#loanInsurance_svg__paint0_linear_3_2246)"
      />
      <path
        d="M6.315 12.901l-1.032-.516h-1.67V4.643H0v9.807h3.613v-1.033h1.426l1.033.516h.638v-1.032h-.395zm-3.734.516H1.032V5.675h1.549v7.742z"
        fill="url(#loanInsurance_svg__paint1_linear_3_2246)"
      />
      <path
        d="M11.1 23.753h-.002c-3.219-.13-5.894-2.867-5.952-6.091h0A6.151 6.151 0 016.9 13.229h0c1.118-1.15 1.778-2.583 1.851-4.068l2.019-1.297.6.601v5.452h4.325l.147-.151.238-.245a6.149 6.149 0 011.484 4.027 6.153 6.153 0 01-1.909 4.48h0a6.152 6.152 0 01-4.555 1.725z"
        stroke="url(#loanInsurance_svg__paint2_linear_3_2246)"
      />
      <path
        d="M13.936 16.514v-.256a1.81 1.81 0 00-1.807-1.808h-.258v-1.033h-1.032v1.033h-.258c-.997 0-1.807.81-1.807 1.806s.81 1.806 1.807 1.806h1.548a.775.775 0 010 1.549h-1.548a.773.773 0 01-.775-.772v-.26H8.774v.26c0 .996.81 1.804 1.807 1.804h.258v1.032h1.032v-1.032h.258c.996 0 1.807-.81 1.807-1.806 0-.997-.81-1.807-1.807-1.807h-1.548a.775.775 0 010-1.548h1.548c.427 0 .774.35.774.776v.256h1.033z"
        fill="url(#loanInsurance_svg__paint3_linear_3_2246)"
      />
      <path
        d="M16.113 7.226c.248-.275.403-.636.403-1.034 0-.853-.695-1.55-1.548-1.55h-1.921l-.36-.448L8.654 3.04 5.49 5.675H3.097v1.032h2.767l3.03-2.524 3.193.912 1.086 1.357c.143.18.207.405.178.633a.836.836 0 01-.33.568.847.847 0 01-1.102-.078l-1.042-1.042-2.941 2.353.645.807 2.22-1.776.388.388c.056.057.12.103.182.151.038.377.175.599.386.832a1.537 1.537 0 00-.402 1.033c0 .398.155.758.402 1.032a1.533 1.533 0 00-.402 1.032 1.55 1.55 0 001.548 1.548h2.065a1.55 1.55 0 001.548-1.547c0-.398-.155-.757-.402-1.032.247-.275.402-.634.402-1.032 0-.399-.155-.758-.402-1.033.247-.274.402-.634.402-1.032s-.155-.757-.403-1.031zM14.968 12.9h-2.065a.517.517 0 010-1.032h2.065a.517.517 0 010 1.032zm0-2.065h-2.065a.517.517 0 010-1.032h2.065a.517.517 0 010 1.032zm0-2.064h-1.913c.206-.062.405-.158.585-.292.26-.195.452-.452.58-.74h.748a.517.517 0 010 1.032zm0-2.065h-.606a1.856 1.856 0 00-.383-.9l-.107-.132h1.096a.517.517 0 010 1.032z"
        fill="url(#loanInsurance_svg__paint4_linear_3_2246)"
      />
      <path
        d="M28.387 24.255v1.548h-3.456l-1.419-.945-4.35-1.543a1.58 1.58 0 00-1.249.083 1.58 1.58 0 00-.805.96c-.22.726.107 1.513.778 1.87l1.117.593-1.624.005-.668-1.67a1.43 1.43 0 00-1.333-.901c-.461 0-.897.224-1.166.598a1.439 1.439 0 00-.194 1.295l.156.463-1.132-.618a1.484 1.484 0 00-2.06.69 1.476 1.476 0 00.694 1.939l2.548 1.257 5.288 1.076 6.862.01 2.013-.403v1.435H32v-7.742h-3.613zm-13.337 1.2c.174-.242.592-.19.702.085l.516 1.29-.93.003-.342-1.015a.398.398 0 01.054-.363zm11.222 4.477h-6.608l-5.045-1.009-2.486-1.227a.448.448 0 01.056-.83.438.438 0 01.358.033l1.773.969h4.899l2.037-1.019-2.885-1.533a.564.564 0 01.01-.998.558.558 0 01.44-.03l4.173 1.465 1.624 1.082h3.77v2.674l-2.116.423zm4.696 1.032h-1.549v-5.677h1.549v5.677z"
        fill="url(#loanInsurance_svg__paint5_linear_3_2246)"
      />
      <defs>
        <linearGradient
          id="loanInsurance_svg__paint0_linear_3_2246"
          x1={5.065}
          y1={2.607}
          x2={17.019}
          y2={2.607}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="loanInsurance_svg__paint1_linear_3_2246"
          x1={-1.258}
          y1={9.599}
          x2={7.884}
          y2={9.599}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="loanInsurance_svg__paint2_linear_3_2246"
          x1={2.129}
          y1={15.834}
          x2={20.413}
          y2={15.834}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="loanInsurance_svg__paint3_linear_3_2246"
          x1={7.806}
          y1={17.591}
          x2={14.839}
          y2={17.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="loanInsurance_svg__paint4_linear_3_2246"
          x1={0.581}
          y1={8.545}
          x2={18.864}
          y2={8.545}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
        <linearGradient
          id="loanInsurance_svg__paint5_linear_3_2246"
          x1={6.882}
          y1={27.657}
          x2={35.702}
          y2={27.657}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#00007E" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgLoanInsurance;
