import React, { useContext } from "react";
import { Navigate } from "react-router";
import Navbar from "../components/navbar/Navbar";
import { Progress } from "antd";
import "antd/dist/antd.css";
import InputName from "../components/inputname/InputName";
import InputBuisnessName from "../components/inputBusinessName/InputBusinessName";
import InputIndustry from "../components/inputIndustry/InputIndustry";
import InputIndustryType from "../components/inputIndustryType/InputIndustryType";
import InputRating from "../components/inputRating/InputRating";
import InputDate from "../components/inputDate/InputDate";
// import AlmostThere from "../components/almostThere/AlmostThere";
import InputGoals from "../components/inputGoals/InputGoals";
import InputInvetorySize from "../components/inputInventorySize/InputInvetorySize";
import { AppContext } from "../context/AppContext";

const Landing = () => {
  const [userInfo] = useContext(AppContext);

  if (!localStorage.getItem("authorization_token")) {
    return <Navigate to="/" />;
  }
  if (userInfo.progress === "100") {
    return <Navigate to="/loading" />;
  } else {
    return (
      <div className=" min-h-screen bg-gray-200">
        <Navbar />
        {console.log(userInfo)}
        <div className="fixed w-full top-14 z-30">
          <Progress
            percent={userInfo.progress}
            status="active"
            showInfo={false}
          />
        </div>

        <div className=" min-h-screen flex justify-center items-center mt-16 ">
          <div className=" p-6 md:p-10 rounded-lg  w-full md:w-9/12 lg:w-1/2">
            <div>
              {userInfo.currentKey === null && (
                <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
                  <div className="text-xl font-semibold pb-2">
                    Welcome to Digital Junction!
                  </div>
                  <div className="tracking-wide">
                    Your one-stop-shop for every Digital need. I am your Digital
                    Dost.
                  </div>
                </div>
              )}
              {userInfo.currentKey === null && <InputName />}
              {userInfo.currentKey === "name" && <InputBuisnessName />}
              {userInfo.currentKey === "bussiness_name" && <InputIndustry />}
              {userInfo.currentKey === "industry_name" && <InputIndustryType />}
              {userInfo.currentKey === "business_type" && <InputRating />}
              {userInfo.currentKey === "rating" && <InputInvetorySize />}
              {userInfo.currentKey === "inventory" && <InputDate />}
              {userInfo.currentKey === "age" && <InputGoals />}
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Landing;
