import * as React from "react";

function SvgTraditionalRestraunt({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15.37 0h1.567c.4.049.8.09 1.2.146 3.537.497 6.59 1.988 9.125 4.503 2.41 2.394 3.92 5.267 4.494 8.622.102.593.163 1.192.244 1.788v1.878l-.078.647c-.357 3.34-1.613 6.295-3.806 8.855-4.103 4.796-10.814 6.73-16.838 4.852-7.65-2.39-12.34-9.94-11.068-17.843.4-2.55 1.425-4.962 2.983-7.02.148-.196.296-.391.435-.578a.5.5 0 00-.049-.084L1.726 3.913c-.448-.45-.916-.883-1.33-1.36C-.255 1.809-.08.725.742.23c.163-.086.33-.162.502-.228h.564c.475.14.826.45 1.164.796.765.783 1.544 1.546 2.314 2.32.175.177.334.368.508.56l.62-.47A15.79 15.79 0 0113.46.219c.627-.103 1.27-.147 1.91-.219zM4.309 6.554C.014 11.592-.729 20.516 5.332 26.625c5.95 6.003 15.808 5.93 21.688-.366 5.598-5.99 5.455-15.646-.902-21.407C19.828-.844 11.2.298 6.568 4.304L8.16 5.897l.48-.34a12.49 12.49 0 016.378-2.3 12.61 12.61 0 016.38 1.16 12.74 12.74 0 012.736 1.732c.264.217.314.49.143.704-.17.215-.45.226-.721.028-.177-.13-.347-.267-.522-.399a10.95 10.95 0 00-2.982-1.607c-3.238-1.136-6.404-.94-9.474.598-.626.313-1.2.723-1.815 1.095.041.053.085.102.131.15.147.133.291.268.444.391 1.475 1.222 3.258 1.476 5.07 1.635.264.023.45.116.507.37.055.24-.052.428-.288.522a3.12 3.12 0 01-.327.103c-1.867.526-3.252 1.653-4.113 3.385-.248.497-.38 1.051-.563 1.581-.087.25-.259.387-.52.346-.244-.039-.436-.218-.374-.455.112-.435-.079-.818-.12-1.224-.112-1.04-.319-2.056-.821-2.99A5.808 5.808 0 006.593 8.8c-1.322 1.757-2.122 3.691-2.362 5.854-.239 2.046.065 4.119.88 6.01a12.62 12.62 0 001.766 2.896c.212.267.184.548-.034.721-.219.173-.486.115-.705-.156-1.895-2.326-2.89-4.993-2.924-7.99-.032-2.832.802-5.41 2.484-7.702.078-.105.15-.216.202-.29L4.308 6.553zm5.189 5.738c.371-.461.765-1.043 1.253-1.53.488-.486 1.081-.893 1.619-1.325-.034-.009-.102-.033-.172-.046-1.663-.297-3.06-1.073-4.245-2.276C6.03 5.165 4.08 3.238 2.144 1.304a2.076 2.076 0 00-.307-.262c-.235-.156-.476-.127-.69.053a.522.522 0 00-.143.675c.08.146.181.278.302.392C3.27 4.13 5.255 6.079 7.194 8.07a8.321 8.321 0 011.39 1.868c.405.758.634 1.61.913 2.353z"
        fill="currentColor"
      />
      <path
        d="M15.918 28.77a12.69 12.69 0 01-5.089-1.095 12.963 12.963 0 01-2.967-1.83c-.267-.217-.317-.486-.143-.705.175-.22.435-.22.724-.032.802.52 1.572 1.102 2.42 1.532 1.979 1.004 4.1 1.34 6.307 1.125 2.777-.273 5.168-1.402 7.148-3.35 1.836-1.805 2.979-3.992 3.367-6.546.498-3.287-.231-6.28-2.208-8.961-.123-.168-.258-.327-.378-.498-.183-.262-.156-.533.053-.696.21-.164.494-.132.682.129.45.626.93 1.234 1.306 1.901 1.244 2.204 1.801 4.588 1.6 7.111-.35 4.312-2.367 7.653-6.01 9.986-2.069 1.33-4.363 1.917-6.812 1.93z"
        fill="currentColor"
      />
      <path
        d="M20.743 16.52a.464.464 0 11-.93.008.464.464 0 01.93-.008zM15.466 19.678a.476.476 0 01-.47-.453.47.47 0 01.44-.475.478.478 0 01.49.464.49.49 0 01-.46.464zM15.465 13.39a.486.486 0 01.466.462.47.47 0 01-.45.47.47.47 0 01-.481-.47.481.481 0 01.465-.461zM13.247 14.4a.482.482 0 01-.46.461.487.487 0 01-.47-.487.47.47 0 01.479-.444.464.464 0 01.45.47zM19.674 14.38a.486.486 0 01-.445.477.474.474 0 01-.483-.47.46.46 0 01.783-.327.47.47 0 01.145.32zM18.063 12.24a.453.453 0 01-.623.44.468.468 0 01-.303-.43.465.465 0 01.786-.327.455.455 0 01.14.318zM18.064 17.06a.457.457 0 01-.44.47.478.478 0 01-.48-.47.457.457 0 01.465-.447.45.45 0 01.455.447zM17.527 14.919a.46.46 0 11-.92.032.447.447 0 01.455-.482.458.458 0 01.465.45zM16.003 16.993a.457.457 0 01-.426-.633.47.47 0 01.436-.294.48.48 0 01.448.47.47.47 0 01-.458.457zM13.776 17.626a.459.459 0 11-.43-.485.47.47 0 01.43.485zM19.136 19.743a.443.443 0 01-.447.459.457.457 0 11-.006-.913.447.447 0 01.453.454z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTraditionalRestraunt;
