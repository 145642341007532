import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../config/axios-instance";
import qs from "qs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AppContext } from "../../context/AppContext";

const Loading = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/post-form",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        industry: userInfo.industry,
        business_type: userInfo.business_type,
        rating: userInfo.rating,
        age: userInfo.age,
        inventory: userInfo.inventry,
        impact: userInfo.impact.toString(),

        // business_type: "Lodge",
        // industry: "Hospitality",
        // rating: 2,
        // age: "Starting Up",
        // inventory: 6 - 10,
        // impact: "Brand Building, Revenue Growth",
        //Revenue Growth",
      }),
    })
      .then(function (response) {
        if (!response) {
          navigate("/journey");
        } else {
          setUserInfo({
            ...userInfo,
            cardData: response.data,
          });
          setIsLoaded(true);
        }
      })
      .catch(function (error) {
        navigate("/onboarding");
        console.log(error);
      });
  }, [navigate, userInfo, setUserInfo]);

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen py-9">
        <div>
          <div className="flex justify-center">
            <Spin indicator={antIcon} />
          </div>
          <div className="uppercase text-2xl font-semibold tracking-widest mt-6 text-gray-600">
            We are building your Digital Journey!
          </div>
        </div>
      </div>
    );
  } else {
    return <div>{navigate("/journey")}</div>;
  }
};

export default Loading;
