import * as React from "react";

function SvgCloudKitchen({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M19.408 24.966c1.642 1.048 3.27 2.083 4.896 3.125.936.602 1.291 1.599.93 2.556-.486 1.295-2.027 1.764-3.195.951-1.574-1.096-3.134-2.214-4.7-3.322-.357-.25-.713-.49-1.055-.756-.198-.155-.337-.154-.547-.004a810.95 810.95 0 01-5.675 4.02c-1.016.71-2.256.55-2.967-.38-.75-.976-.497-2.342.579-3.038 1.35-.874 2.708-1.734 4.062-2.6l.89-.574-1.164-.828c-1.27-.893-2.54-1.79-3.801-2.696-.192-.137-.336-.165-.565-.06C3.636 22.943.011 20.087 0 16.906a3.918 3.918 0 01.905-2.512 3.937 3.937 0 012.306-1.353c2.545-.493 5.224 1.147 5.881 3.635.13.495.136 1.027.164 1.544.01.188.052.297.213.4 2.115 1.344 4.226 2.694 6.335 4.049.16.103.267.088.413-.01 2.108-1.353 4.22-2.703 6.335-4.05.171-.108.197-.23.19-.41-.142-3.272 2.642-5.228 5.074-5.238 3.182-.02 5.088 2.947 3.752 5.835-1.033 2.232-3.888 3.885-6.749 2.542a.422.422 0 00-.356.017 776.016 776.016 0 00-4.998 3.536c-.015.007-.02.03-.057.075zM4.125 14a8.498 8.498 0 00-.853.122 2.865 2.865 0 00-2.178 2.342 2.85 2.85 0 00.048 1.121c.6 2.584 3.54 3.955 5.758 2.664.388-.226.669-.199 1.024.053 4.383 3.117 8.771 6.226 13.164 9.328.524.372 1.044.752 1.578 1.111a1.029 1.029 0 001.502-.405c.085-.178.117-.376.092-.572-.046-.4-.313-.636-.635-.841-4.963-3.17-9.927-6.337-14.89-9.5-.478-.303-.737-.61-.555-1.179a.963.963 0 00.02-.266C8.244 15.825 6.39 14.024 4.124 14zm12.872 9.42c.395.254.77.472 1.118.726.216.158.362.142.577-.012 1.82-1.305 3.655-2.593 5.48-3.893.25-.177.49-.258.764-.083 1.743 1.104 3.89.64 5.204-1.076.826-1.077 1.095-2.278.445-3.546-.582-1.145-1.913-1.708-3.363-1.46-2.038.35-3.744 2.127-3.358 4.425.06.355-.072.58-.373.77-1.808 1.145-3.608 2.303-5.412 3.456l-1.082.694zm-1.92 3.26c-.452-.322-.89-.621-1.31-.938-.149-.111-.258-.104-.412-.005-1.704 1.097-3.414 2.185-5.117 3.285a1.252 1.252 0 00-.38.404.975.975 0 00.178 1.19 1.035 1.035 0 001.316.114c.552-.37 1.088-.764 1.63-1.148l4.095-2.902z"
        fill="currentColor"
      />
      <path
        d="M22.256 18.286l-1.034-.214.282-1.476H11.253c.09.49.184.966.282 1.483l-1.042.206c-.187-.926-.368-1.818-.547-2.71-.311-1.55-.627-3.097-.927-4.644-.04-.21-.136-.268-.324-.296-2.55-.388-4.387-2.733-4.094-5.22.311-2.645 2.536-4.517 5.157-4.34.829.057 1.359.256 2.625.991 1.942-2.746 5.997-2.769 7.996.016a5.108 5.108 0 011.95-.905C24.97.6 27.634 2.338 28.106 4.954c.495 2.746-1.3 5.287-4.023 5.68-.227.032-.31.117-.359.343-.453 2.315-.923 4.628-1.388 6.94-.018.107-.046.214-.08.369zm-.537-2.789c.03-.127.06-.244.084-.363l1.001-4.975c.08-.393.234-.509.641-.535.256-.014.51-.048.761-.102 1.58-.38 2.686-1.642 2.877-3.267.18-1.49-.677-3.037-2.053-3.716a3.74 3.74 0 00-4.281.648c-.476.446-.758.395-1.094-.179-.75-1.281-1.88-1.96-3.362-1.928-1.443.03-2.521.728-3.228 1.986-.278.493-.609.549-1.013.167-.033-.03-.064-.062-.097-.089a3.736 3.736 0 00-4.08-.672 3.747 3.747 0 00-2.22 3.518A3.747 3.747 0 006.731 8.51a3.764 3.764 0 002.505 1.118c.499.022.62.12.723.604.09.403.166.804.248 1.21.275 1.347.547 2.694.816 4.041h2.05l-.44-3.104 1.057-.148.472 3.256h1.696V9.644h1.064v5.846h1.671l.476-3.268 1.054.16-.441 3.115h2.037zM22.716 25.142l-.622-.819c.215-.148.418-.292.627-.43.57-.38 1.131-.772 1.722-1.127a.916.916 0 01.622-.112c2.278.613 4.304.187 6.098-1.25.025-.02.055-.035.11-.072l.726.73a7.5 7.5 0 01-3.18 1.654 7.84 7.84 0 01-3.637.025.67.67 0 00-.453.078c-.68.426-1.335.872-2.013 1.323z"
        fill="currentColor"
      />
      <path
        d="M5.515 0l.58.878C3.71 2.731 2.9 5.088 3.706 8.034l-.971.347C1.652 5.813 2.505 1.913 5.515 0zM3.047 9.635l.877-.494c.215.289.423.577.647.874l-.755.65-.77-1.03z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCloudKitchen;
