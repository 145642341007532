import * as React from "react";

function SvgBuisnessHotels({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.54 11.102h6.668v1h-7.05c-1.074 0-1.706.613-1.706 1.66v8.793c0 1.028.634 1.649 1.686 1.649h13.158c1.027 0 1.668-.628 1.668-1.635v-8.73c0-1.146-.594-1.732-1.757-1.737H28.9v-.963c1.569-.33 3.077.87 3.092 2.461.015 1.786.004 3.572.004 5.357v3.625c-.004 1.552-1.111 2.63-2.704 2.63H16.326c-1.55 0-2.37-.572-2.867-2.004H1.06v.99h11.828v1.014H.747c-.594 0-.744-.145-.744-.723v-1.608c0-.538.157-.691.708-.694h.304V3.024c-.167 0-.326.007-.483 0-.323-.018-.517-.204-.525-.522-.01-.661-.01-1.323 0-1.985C.012.2.213.022.539.002c.086-.005.173 0 .259 0h15.964c.656 0 .787.13.787.776v1.545c0 .554-.154.701-.73.705h-.279v8.074zm-1.08.033V3.051H2.083v19.117h3.068V19.18c-.419-.084-.517-.361-.508-.744.018-.566 0-1.134.005-1.701 0-.412.19-.598.61-.598h7.03c.422 0 .611.185.614.595.005.577 0 1.155 0 1.732 0 .461-.06.549-.493.726v2.97h1.01v-.403-8.096c0-1.174.7-2.098 1.84-2.46.073-.021.141-.045.201-.068v.002zm1.041-10.102H1.061v.971h15.44v-.97zM9.307 22.171h2.025v-2.994H9.307v2.994zm-3.09-2.99v2.98h2.025v-2.985l-2.024.005zm5.639-2.015H5.7v.968h6.155v-.968z"
        fill="currentColor"
      />
      <path
        d="M5.981 7.998H4.595c-.407 0-.59-.18-.592-.588C4 6.467 4 5.524 4.003 4.58c0-.393.181-.573.574-.576.942-.007 1.885-.007 2.828 0 .41 0 .59.18.592.586.004.943.004 1.886 0 2.83 0 .39-.186.572-.574.577-.48.005-.961 0-1.442 0zm1.036-.984V4.996H4.995v2.022l2.022-.004zM7.999 10.985v1.39c0 .45-.174.624-.613.625H4.613c-.44 0-.611-.174-.613-.625v-2.75c0-.456.166-.62.613-.622.924-.004 1.849-.004 2.772 0 .449 0 .61.169.614.62.003.452 0 .908 0 1.362zm-3.01-1.002v2.028H7.01V9.983H4.989zM12.998 5.995c0 .48.004.96 0 1.441-.003.379-.18.558-.557.56-.956.005-1.913.005-2.87 0-.392 0-.568-.183-.569-.577C9 6.467 9 5.515 9.002 4.564c0-.379.174-.556.558-.559.957-.007 1.913-.007 2.87 0 .4 0 .565.177.568.576.003.471 0 .943 0 1.414zM9.987 4.99v2.023h2.024V4.99H9.988zM12.998 11.025c0 .472.004.944 0 1.416-.004.38-.18.554-.56.556-.956.004-1.913.004-2.87 0-.39 0-.565-.181-.566-.579-.003-.943-.003-1.888 0-2.833 0-.404.164-.578.562-.58a226.12 226.12 0 012.87 0c.402 0 .563.17.566.574.001.48-.002.964-.002 1.446zm-.988.992V9.99H9.985v2.027h2.025z"
        fill="currentColor"
      />
      <g clipPath="url(#buisnessHotels_svg__clip0_312_546)">
        <path
          d="M28 21.442c-.056.215-.149.406-.334.542a.802.802 0 01-.45.162c-.037 0-.073.003-.108.003h-8.195a.885.885 0 01-.526-.143.823.823 0 01-.367-.713v-5.36c0-.058.005-.116.015-.173a.81.81 0 01.783-.675c.66-.008 1.319-.003 1.978-.004h.132c0-.073-.003-.14 0-.206.008-.142.002-.288.034-.425a.793.793 0 01.763-.613c.854-.006 1.708-.008 2.562 0 .457.005.794.372.802.847.002.13 0 .258 0 .397h2c.491 0 .772.216.9.692 0 .005.007.01.011.015v5.654zm-9.566-3.15v2.956c0 .325.161.487.483.487h8.184c.323 0 .484-.162.485-.486v-2.957c-.244.147-.497.113-.745.114h-2.998c0 .206.002.4 0 .594-.002.16-.083.238-.243.239h-1.189c-.148 0-.23-.08-.234-.228-.004-.165 0-.33-.001-.496 0-.035-.004-.069-.006-.11H19.14c-.235 0-.475.027-.706-.114v0zm5.409-.3h3.274c.302 0 .469-.167.469-.472v-1.549c0-.31-.166-.474-.476-.474h-8.195c-.318 0-.48.162-.481.479v1.539c0 .313.164.477.473.477h3.27c0-.069-.002-.127 0-.184.005-.151.08-.229.23-.23h1.209c.143 0 .22.079.226.224.003.058.001.117.001.19v0zm-2.49-2.916h3.317c0-.15.005-.296-.002-.442a.4.4 0 00-.392-.383c-.844-.003-1.688-.003-2.531 0-.195 0-.374.157-.39.344-.013.156-.002.316-.002.481zM22.6 18.82h.82V18h-.82v.82z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth={0.4}
        />
      </g>
      <defs>
        <clipPath id="buisnessHotels_svg__clip0_312_546">
          <path fill="#fff" transform="translate(18 13)" d="M0 0h10v10H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBuisnessHotels;
