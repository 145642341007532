import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import axios from "../../config/axios-instance";
import qs from "qs";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const InputDate = () => {
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [currentKey, setCurrentKey] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  let age = "Starting Up";
  const onChange = (date) => {
    const currentdate = Date.now();
    const year = Math.abs(
      new Date(currentdate - date._d.getTime()).getUTCFullYear() - 1970
    );
    console.log(year);
    switch (year) {
      case 0:
        age = "Starting Up";
        break;
      case 1:
        age = "0-1 year";
        break;
      case 2:
      case 3:
        age = "1-3 years";
        break;
      case 4:
      case 5:
      case 6:
      case 7:
        age = "3-7 years";
        break;
      case 8:
      case 9:
      case 10:
        age = "7-10 years";
        break;
      default:
        age = "10+ years";
    }
    console.log("year", year, "age", age);
  };

  const handleSubmit = () => {
    setUserInfo({
      ...userInfo,
      age: age,
      progress: "97",
      currentKey: currentKey,
    });
  };

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/get_by_previous_option",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        q_number: "5",
        previous_op: userInfo.inventry,
        industry: userInfo.industry,
      }),
    })
      .then(function (response) {
        setCurrentKey(response.data.current_key);
        setIsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [userInfo]);

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center w-full py-9">
        <Spin tip="Loading..." indicator={antIcon} />
      </div>
    );
  } else {
    return (
      <div className=" shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
        <div className="text-xl">
          And since when have you been running the {userInfo.business_type}?
        </div>
        <div className="flex items-center justify-center pt-4">
          <DatePicker onChange={onChange} size="large" />
        </div>
        <div className="flex justify-center w-full">
          <button
            className="bg-blue-600 rounded-md mt-10 font-semibold p-1 px-2 transform hover:scale-125 transition duration-200 ease-in-out hover:bg-blue-700 text-white"
            onClick={handleSubmit}
          >
            confirm
          </button>
        </div>
      </div>
    );
  }
};

export default InputDate;
