import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import { FaCircle } from "react-icons/fa";
import PlotCard from "../components/plotCard/PlotCard";
import { AppContext } from "../context/AppContext";

const SolutionPlot = () => {
  const [userInfo] = useContext(AppContext);
  const data = {
    "Brand Building": {
      product_map: [
        {
          "SKU Name": "Jini Hub",
          "Solution by": "Bookingjini Labs Pvt. Ltd.",
          "Price Starting from": "24,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "CRS",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Hub.svg",
          score: 6979892337325250835175956,
        },
        {
          "SKU Name": "Jini Hive (Channel Manager)",
          "Solution by": "Bookingjini Labs Pvt. Ltd.",
          "Price Starting from": "16,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "Channel Manager",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Hive.svg",
          score: 6960113790309023675636244,
        },
      ],
      solution_map: [
        {
          solution: "CRS",
          score: 7979892337321250835175960,
        },
        {
          solution: "Channel Manager",
          score: 7960113790305023675636248,
        },
        {
          solution: "GDS",
          score: 7978067327231478463560599,
        },
        {
          solution: "Channel Manager",
          score: 7960113790305023675636248,
        },
        {
          solution: "CRS",
          score: 7979892337321250835175960,
        },
        {
          solution: "Revenue Manager",
          score: 7977953668755504606912515,
        },
        {
          solution: "Domain Name",
          score: 7959780488821367431640727,
        },
      ],
    },
    "Revenue Growth": {
      product_map: [
        {
          "SKU Name": "Jini Hub",
          "Solution by": "Bookingjini Labs Pvt. Ltd.",
          "Price Starting from": "24,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "CRS",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Hub.svg",
          score: 6979892337325250835175956,
        },
      ],
      solution_map: [
        {
          solution: "CRS",
          score: 7979892337309250835176020,
        },
        {
          solution: "Channel Manager",
          score: 7960113790293023675636308,
        },
        {
          solution: "GDS",
          score: 7978067327219478463560659,
        },
        {
          solution: "Channel Manager",
          score: 7960113790293023675636308,
        },
        {
          solution: "CRS",
          score: 7979892337309250835176020,
        },
        {
          solution: "Revenue Manager",
          score: 7977953668739504606912579,
        },
        {
          solution: "Domain Name",
          score: 7959780488841367431640627,
        },
      ],
    },
    "Distribution Efficiency": {
      product_map: [
        {
          "SKU Name": "Jini Hub",
          "Solution by": "Bookingjini Labs Pvt. Ltd.",
          "Price Starting from": "24,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "CRS",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Hub.svg",
          score: 6979892337325250835175956,
        },
        {
          "SKU Name": "Jini Hive (Channel Manager)",
          "Solution by": "Bookingjini Labs Pvt. Ltd.",
          "Price Starting from": "16,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "Channel Manager",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Hive.svg",
          score: 6960113790309023675636244,
        },
        {
          "SKU Name": "Jini Globe",
          "Solution by": "Bookingjini Labs Pvt. Ltd.",
          "Price Starting from": "22,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "GDS",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Globe.svg",
          score: 6978067327235478463560595,
        },
        {
          "SKU Name": "DJUBO Instakonnekt (Channel Manager)",
          "Solution by": "Saaranya Hospitality Technologies Pvt. Ltd.",
          "Price Starting from": "16,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "Channel Manager",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Voice.svg",
          score: 6960113790309023675636244,
        },
        {
          "SKU Name": "DJUBO CRS",
          "Solution by": "Saaranya Hospitality Technologies Pvt. Ltd.",
          "Price Starting from": "16,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "CRS",
          "Solution Sub-Category": "Distribution Management",
          Station: "Distribution Management",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Voice.svg",
          score: 6979892337325250835175956,
        },
        {
          "SKU Name": "DJUBO Cerebrum (Intelligent Revenue  Manager)",
          "Solution by": "Saaranya Hospitality Technologies Pvt. Ltd.",
          "Price Starting from": "22,000/year",
          "Rating ": "4.2",
          "Solution Type": "Cloud Software",
          "Solution ": "Revenue Manager",
          "Solution Sub-Category": "Reveneu Efficiency",
          Station: "Business Growth",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Voice.svg",
          score: 6977953668755504606912515,
        },
        {
          "SKU Name": ".<variable> Doamin Name (Based on the Type & Name)",
          "Solution by": "ICANN",
          "Price Starting from": "1,500/year",
          "Rating ": "4.2",
          "Solution Type": "Web Address",
          "Solution ": "Domain Name",
          "Solution Sub-Category": "Independent Identity",
          Station: "Independent Identity",
          image_url:
            "https://digital-junction-icon-storage.s3.ap-south-1.amazonaws.com/Jini+Voice.svg",
          score: 6959780488841367431640627,
        },
      ],
      solution_map: [
        {
          solution: "CRS",
          score: 7979892337305250835176056,
        },
        {
          solution: "Channel Manager",
          score: 7960113790289023675636344,
        },
        {
          solution: "GDS",
          score: 7978067327215478463560695,
        },
        {
          solution: "Channel Manager",
          score: 7960113790289023675636344,
        },
        {
          solution: "CRS",
          score: 7979892337305250835176056,
        },
        {
          solution: "Revenue Manager",
          score: 7977953668755504606912515,
        },
        {
          solution: "Domain Name",
          score: 7959780488841367431640627,
        },
      ],
    },
  };
  const plotData = data["Brand Building"].solution_map;

  const [distance, setDistance] = useState({});

  useEffect(() => {
    var byDistance = plotData.slice(0);
    byDistance.sort(function (a, b) {
      return a.score - b.score;
    });
    setDistance(byDistance);
  }, []);

  return (
    <div className=" min-h-screen bg-gray-200">
      <Navbar />
      {console.log(plotData)}
      <div className=" min-h-screen flex justify-center mb-16 mt-16 ">
        <div className="shadow-2xl md:px-10 rounded-lg bg-white mt-2 w-3/4">
          <div className="mt-6">
            <div className="px-4">
              <div className="flex">
                <div className="ml-3 w-16 m-1 "></div>
                <FaCircle className="text-blue-700" />
              </div>
              <div className="overflow-y-auto h-full">
                {Object.values(distance).map((data, key) => {
                  return <PlotCard name={data} key={key} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionPlot;
