import React, { useContext, useState } from "react";
import { FiSend } from "react-icons/fi";
import { Spring, animated } from "react-spring";
import { AppContext } from "../../context/AppContext";
import useSound from "use-sound";
import soundTwo from "../../assets/sounds/soundTwo.wav";

const InputBuisnessName = () => {
  const [userInfo, setUserInfo] = useContext(AppContext);
  const [buisnessName, setBuisnessName] = useState({ buisnessName: null });
  const [play] = useSound(soundTwo);

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserInfo({
      ...userInfo,
      buisnessName: buisnessName.buisnessName,
      progress: "26",
      currentKey: "bussiness_name",
    });
  };
  const onChange = (e) => {
    setBuisnessName({ ...buisnessName, [e.target.name]: e.target.value });
    console.log(buisnessName);
  };

  return (
    <Spring
      from={{ opacity: 0, marginBottom: -500 }}
      to={{ opacity: 1, marginBottom: 0 }}
    >
      {(styles) => (
        <animated.div style={styles}>
          <div className="shadow-2xl p-6 md:p-10 rounded-lg bg-white m-4 transform hover:scale-105 transition duration-200 ease-in-out">
            <div className="text-xl">
              Great! And what’s your business called?
            </div>
            <form className="flex" onSubmit={handleSubmit}>
              <input
                name="buisnessName"
                className="m-2 text-center md:text-left w-full border-b-2 border-blue-300 text-xl"
                type="text"
                placeholder="Enter business name here..."
                onChange={onChange}
                required
              ></input>
              <button
                type="submit"
                className="self-center flex items-center justify-center rounded-full h-10 w-11 cursor-pointer transition duration-200 ease-in-out bg-blue-600 hover:bg-blue-800 transform hover:-translate-y-1 hover:scale-110"
                onClick={play}
              >
                <FiSend className="" size="24" color="white" />
              </button>
            </form>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};

export default InputBuisnessName;
